



export const tocsv = (data) => {
    /* take and array of objects with identical schemas and convert to comma-delimited string. */
    const columnDelimiter = ","
    const lineDelimiter = "\n"
    let result, ctr, keys
    if (data === null || !data.length) {
      return null
    }
    keys = Object.keys(data[0])
    result = ""
    result += keys.join(columnDelimiter)
    result += lineDelimiter
    data.forEach(item => {
      ctr = 0
      keys.forEach(key => {
        if (ctr > 0) {
          result += columnDelimiter
        }
        result += typeof item[key] === "string" && item[key].includes(columnDelimiter) ? `"${item[key]}"` : item[key]
        ctr++
      })
      result += lineDelimiter
    })
    return result
  }
  