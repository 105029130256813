import { combineReducers } from 'redux';
import redDashboard from './redDashboard';
import redUI from './redUI';
import keplerGlReducer from 'kepler.gl/reducers';


const redKepler = keplerGlReducer.initialState({
    uiState: {
       currentModal: null
    }
  });

/// construct the root redeucer
const rootReducer = combineReducers({
    keplerGl: redKepler,
    redDashboard,
    redUI,
});



export default rootReducer;