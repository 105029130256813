import { Box, Grid, Typography } from '@material-ui/core'
import React from 'react'
import MLCell from '../../components/ml/MLCell'
import { plots, videos } from './content'
import { ReactComponent as DeepNet } from '../../assets/others/deepNet.svg';


export default function ANN() {

    return (
        <div style={{ marginTop: "70px", marginLeft: "auto", marginRight: "auto", maxWidth: "1400px" }}>
            <Box display="flex" justifyContent="center">
                <DeepNet style={{ marginTop: "80px", width: "80%", height: "100%" }} />
            </Box>

            <Box display="flex" justifyContent="center">
                <Typography variant="body1">h<sub>W,b</sub>(X) = &phi;(X.W+b)</Typography>
            </Box>

            <Grid container spacing={2} style={{ marginTop: "50px" }}>
                {plots.map((e, i) => {
                    return (
                        <>
                        <Grid key={i} item md={4} sm={12} xs={12}>
                            <Grid container spacing={0}>
                                <Grid key={i} item md={12} sm={12} xs={12}>
                                    <MLCell
                                        title={plots[i].title}
                                        plot={plots[i].plot}
                                        caption={plots[i].caption}
                                    />
                                </Grid>

                                <Grid key={plots.length + i} item md={12} sm={12} xs={12}>
                                    <MLCell
                                        title={videos[i].title}
                                        videoLink={videos[i].link}
                                        caption={videos[i].caption}
                                    />
                                </Grid>
                            </Grid>
                            </Grid>
                        </>
                    )
                }
                )}
            </Grid>
        </div>
    )
}
