import React from 'react'
import Plot from 'react-plotly.js'
import useWindowSize from '../../utils/useWindowSize'





const Sunburst = ({transparent}) => {
    const { height, width } = useWindowSize();

    let config = {
                  displaylogo: false,
                  displayModeBar: false,
                  responsive: true,
                  scrollZoom: true,
                  editable: true,
                  showLink: false,
                  staticPlot: false,
                  toImageButtonOptions: {
                    format: 'png', // png, svg, jpeg, webp
                    filename: 'Taxonomy',
                    height: 820,
                    width: 1200,
                    scale: 5 
                  },  
                  modeBarButtonsToRemove: ['pan2d','select2d','lasso2d','resetScale2d','zoomOut2d'],
                }



    let data = [{
        type: "sunburst",
        ids: [
          // first layer
          "CMAP", 
          // second layer
          "In-Situ", "Remote Sensing", "Numerical Model", 
          // third layer
          "Cruise", "Autonomous",    "Satellite", "Airborne",   "Mechanistic", "Statistics", 
          // fourth layer
          "Float", "Glider", "CTD", "Flow Cytometry", "Climatology", "Others",     "AOD", "CHL", "SST", "SSH", "SSS", "POC", "PAR",       "Darwin", "PISCES",    
          // fifth layer
          "SeaFlow", "Global Compilation", "AMT", "World Ocean Atlas", "Argo MLD", "ECCO2", "Argo", "Ecosystem", "Ocean Circulation"

        ],
        labels: [
          // first layer
          "Simons<br>CMAP", 
          // second layer
          "In-Situ", "Remote<br>Sensing", "Numerical<br>Model", 
          // third layer
          "Cruise<br>Expedition", "Autonomous<br>Instrument",    "Satellite", "Airborne",   "Mechanistic", "Statistical", 
          // fourth layer
          "Float", "Glider", "CTD", "Flow<br>Cytometry", "Climatology", "Others",     "AOD", "CHL", "SST", "SSH", "SSS", "POC", "PAR",       "MIT<br>Darwin", "PISCES",           
          // fifth layer
          "SeaFlow", "Global<br>Compile", "AMT",  "World<br>Ocean<br>Atlas", "Argo<br>MLD", "ECCO2", "Argo", "Ocean<br>Ecosystem", "Ocean<br>Circulation"

        ],
        parents: [
          // first layer  
          "", 
          // second layer  
          "CMAP", "CMAP", "CMAP", 
          // third layer  
          "In-Situ", "In-Situ",   "Remote Sensing", "Remote Sensing",   "Numerical Model", "Numerical Model", 
          // fourth layer  
          "Autonomous", "Autonomous", "Cruise", "Cruise", "Cruise", "Cruise",     "Satellite", "Satellite", "Satellite", "Satellite", "Satellite", "Satellite", "Satellite",       "Mechanistic", "Mechanistic",           
          // fifth layer  
          "Flow Cytometry", "Flow Cytometry", "Flow Cytometry", "Climatology", "Climatology", "Climatology", "Float", "Darwin", "Darwin"
        ],
        outsidetextfont: {size: 20, color: "#377eb8"},
        // leaf: {opacity: 0.4},
        marker: {line: {width: 2}},
      }];
      
      let layout = {
            // autosize: true,
            // width: Math.floor(0.98*width),
            // height: 500,
            width: 400,
            height: 400,
        margin: {l: 0, r: 0, b: 0, t:0},
        sunburstcolorway:["#636efa","#ef553b","#00cc96"],
      };    

      if (transparent) {
          layout = {...layout, paper_bgcolor: 'rgba(0,0,0,0)', plot_bgcolor: 'rgba(0,0,0,0)',}
      }


    return (
            <Plot data={data} layout={layout} config={config}/>       
    )
}


export default Sunburst;