


////// action types for dashboard
export const DASHBOARD_DATA = 'DASHBOARD_DATA';






////// sagas: action types for statistical calculations  (stat panel)
export const STAT_CLAC_START = 'STAT_CLAC_START';
export const STAT_CALC_SUCCESS = 'STAT_CALC_SUCCESS';
export const STAT_CLAC_FAILURE = 'STAT_CLAC_FAILURE';