import * as React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import light from '../../style/theme/light';
import MainNavbar from '../ui/primary-navbar/MainNavbar';
import Home from '../../pages/home/Home';
import ML from '../../pages/ml/ML';
import Publications from '../../pages/publications/Publications';
import About from '../../pages/about/About';
import DataSubmissionGuide from '../../pages/data-submission-guide/DataSubmissionGuide';
import Species from '../../pages/species/Species';
import Dashboard from '../../pages/dashboard/Dashboard';



function App() {
  return (
    <ThemeProvider theme={light}>      
      <BrowserRouter>
        <MainNavbar/>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/species' component={Species} />
          <Route exact path='/dashboard' component={Dashboard} />
          <Route exact path='/ml' component={ML} />
          {/* <Route exact path='/turbulence' component={Turbulence} /> */}
          <Route exact path='/publications' component={Publications} />
          <Route exact path='/about' component={About} />
          <Route exact path='/datasubmission/guide' component={DataSubmissionGuide} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
