import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import SpeciesTable from '../../../components/species/species-table/SpeciesTable'
import Sankey from '../figs/sankey-plotly/Sankey';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { styled } from '@mui/system';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import './speciesOptions.css'
import speciesImage from '../../../assets/others/plankton1.jpeg'
import PackedBubble from '../figs/packed-bubble/PackedBubble';

const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#80BFFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2',
    800: '#004C99',
    900: '#003A75',
};

const Tab = styled(TabUnstyled)`
    font-family: IBM Plex Sans, sans-serif;
    color: white;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: bold;
    background-color: transparent;
    width: 100%;
    padding: 12px 16px;
    margin: 6px 6px;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
  
    &:hover {
      background-color: ${blue[400]};
    }
  
    &:focus {
      color: #fff;
      border-radius: 3px;
      outline: 2px solid ${blue[200]};
      outline-offset: 2px;
    }
  
    &.${tabUnstyledClasses.selected} {
      background-color: ${blue[50]};
      color: ${blue[600]};
    }
  
    &.${buttonUnstyledClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `;

const TabPanel = styled(TabPanelUnstyled)`
    width: 100%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
  `;

const TabsList = styled(TabsListUnstyled)`
    min-width: 320px;
    background-color: ${blue[500]};
    border-radius: 8px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
  `;


//////////////////////



const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function SpeciesOptions(props) {
    const { domain, kingdom, phylum, Class, order, family, genus, species, trophicLevels, subtrophicLevels, functional, paraphyletic, unicell } = props;
    const [domainFilters, setDomainFilters] = React.useState([]);
    const [kingdomFilters, setKingdomFilters] = React.useState([]);
    const [phylumFilters, setPhylumFilters] = React.useState([]);
    const [classFilters, setClassFilters] = React.useState([]);
    const [orderFilters, setOrderFilters] = React.useState([]);
    const [familyFilters, setFamilyFilters] = React.useState([]);
    const [genusFilters, setGenusFilters] = React.useState([]);
    const [speciesFilters, setSpeciesFilters] = React.useState([]);
    const [trophicFilters, setTrophicFilters] = React.useState([]);
    const [subtrophicFilters, setSubtrophicFilters] = React.useState([]);
    const [functionalFilters, setFunctionalFilters] = React.useState([]);
    const [paraphyleticFilters, setParaphyleticFilters] = React.useState([]);
    const [unicellFilters, setUnicellFilters] = React.useState([]);

    let filters = {
        domain: domainFilters,
        kingdom: kingdomFilters,
        phylum: phylumFilters,
        Class: classFilters,
        order: orderFilters,
        family: familyFilters,
        genus: genusFilters,
        species: speciesFilters,
        trophic: trophicFilters,
        subtrophic: subtrophicFilters,
        functional: functionalFilters,
        paraphyletic: paraphyleticFilters,
        unicell: unicellFilters,
    }

    const propsReady = () => {
        let len = 1;
        for (const key in props) {
            if (key == "species") { continue }
            len *= props[key].length
        }
        return !!len
    }

    return (
        <div className="species-options-container">
            <img src={speciesImage} className="species-image" />
            <Typography variant='caption' style={{ fontSize: 8 }}>Image Credit: https://ecotaxa.obs-vlfr.fr/</Typography>
            <Card>
                <CardContent>
                    <Typography variant="h4">Filter Species by</Typography>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={6}>
                            <fieldset className="fieldset-species-filters">
                                <legend >Taxonomy</legend>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={6} md={6}>

                                        <Autocomplete
                                            value={domainFilters}
                                            onChange={(event, newValue) => {
                                                setDomainFilters(newValue);
                                                filters = { ...filters, domain: newValue };
                                            }}
                                            multiple
                                            id="taxonomy-domain"
                                            options={domain}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.title}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.title}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Domain" placeholder="Domain" />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Autocomplete
                                            value={kingdomFilters}
                                            onChange={(event, newValue) => {
                                                setKingdomFilters(newValue);
                                                filters = { ...filters, kingdom: newValue };
                                            }}
                                            multiple
                                            id="taxonomy-kingdom"
                                            options={kingdom}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.title}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.title}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Kingdom" placeholder="Kingdom" />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Autocomplete
                                            value={phylumFilters}
                                            onChange={(event, newValue) => {
                                                setPhylumFilters(newValue);
                                                filters = { ...filters, phylum: newValue };
                                            }}
                                            multiple
                                            id="taxonomy-phylum"
                                            options={phylum}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.title}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.title}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Phylum" placeholder="Phylum" />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Autocomplete
                                            value={classFilters}
                                            onChange={(event, newValue) => {
                                                setClassFilters(newValue);
                                                filters = { ...filters, Class: newValue };
                                            }}
                                            multiple
                                            id="taxonomy-class"
                                            options={Class}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.title}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.title}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Class" placeholder="Class" />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Autocomplete
                                            value={orderFilters}
                                            onChange={(event, newValue) => {
                                                setOrderFilters(newValue);
                                                filters = { ...filters, order: newValue };
                                            }}
                                            multiple
                                            id="taxonomy-order"
                                            options={order}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.title}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.title}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Order" placeholder="Order" />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Autocomplete
                                            value={familyFilters}
                                            onChange={(event, newValue) => {
                                                setFamilyFilters(newValue);
                                                filters = { ...filters, family: newValue };
                                            }}
                                            multiple
                                            id="taxonomy-family"
                                            options={family}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.title}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.title}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Family" placeholder="Family" />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Autocomplete
                                            value={genusFilters}
                                            onChange={(event, newValue) => {
                                                setGenusFilters(newValue);
                                                filters = { ...filters, genus: newValue };
                                            }}
                                            multiple
                                            id="taxonomy-genus"
                                            options={genus}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.title}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.title}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Genus" placeholder="Genus" />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <Autocomplete
                                            value={speciesFilters}
                                            onChange={(event, newValue) => {
                                                setSpeciesFilters(newValue);
                                                filters = { ...filters, species: newValue };
                                            }}
                                            multiple
                                            id="taxonomy-species"
                                            options={species}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.title}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.title}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Species" placeholder="Species" />
                                            )}
                                        />
                                    </Grid>

                                </Grid>
                            </fieldset>
                        </Grid>



                        {/* **************  Functional Filters **************  */}
                        <Grid item xs={12} sm={12} md={6}>
                            <fieldset className="fieldset-species-filters">
                                <legend >Attributes</legend>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={12}>
                                        <Autocomplete
                                            value={functionalFilters}
                                            onChange={(event, newValue) => {
                                                setFunctionalFilters(newValue);
                                                filters = { ...filters, functional: newValue };
                                            }}
                                            multiple
                                            id="functional-groups"
                                            options={functional}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.title}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.title}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Functional Group" placeholder="Functional Group" />
                                            )}
                                        />
                                    </Grid>


                                    <Grid item xs={12} md={12}>
                                        <Autocomplete
                                            value={paraphyleticFilters}
                                            onChange={(event, newValue) => {
                                                setParaphyleticFilters(newValue);
                                                filters = { ...filters, paraphyletic: newValue };
                                            }}
                                            multiple
                                            id="paraphyletic-groups"
                                            options={paraphyletic}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.title}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.title}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Paraphyletic Group" placeholder="Paraphyletic Group" />
                                            )}
                                        />
                                    </Grid>



                                    <Grid item xs={12} md={12}>
                                        <Autocomplete
                                            value={unicellFilters}
                                            onChange={(event, newValue) => {
                                                setUnicellFilters(newValue);
                                                filters = { ...filters, unicell: newValue };
                                            }}
                                            multiple
                                            id="unicellularity-groups"
                                            options={unicell}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.title}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.title}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Unicellularity" placeholder="Unicellularity" />
                                            )}
                                        />
                                    </Grid>

                                



                                {/* **************  Trophic Filters **************  */}

                                
                                    <Grid item xs={12} md={6}>
                                        <Autocomplete
                                            value={trophicFilters}
                                            onChange={(event, newValue) => {
                                                setTrophicFilters(newValue);
                                                filters = { ...filters, trophic: newValue };
                                            }}
                                            multiple
                                            id="trophic-levels"
                                            options={trophicLevels}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.title}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.title}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Trophic Level" placeholder="Trophic Level" />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Autocomplete
                                            value={subtrophicFilters}
                                            onChange={(event, newValue) => {
                                                setSubtrophicFilters(newValue);
                                                filters = { ...filters, subtrophic: newValue };
                                            }}
                                            multiple
                                            id="subtrophic-levels"
                                            options={subtrophicLevels}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.title}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.title}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Subtrophic Level" placeholder="SubTrophic Level" />
                                            )}
                                        />
                                    </Grid>
                                    </Grid>
                            </fieldset>
                        </Grid>

                    </Grid>
                </CardContent>
            </Card>


            <div className="species-tab-container">
                <Card>
                    <CardContent>
                        {/* <Typography variant="h4">Species</Typography> */}
                        <Grid container spacing={1}>
                            <Grid item xs={12} xl={12}>
                                <TabsUnstyled defaultValue={0}>
                                    <TabsList>
                                        <Tab>Compile Datasets</Tab>
                                        <Tab>Taxonomy Diagram</Tab>
                                        <Tab>Functional Diagram</Tab>
                                    </TabsList>
                                    <TabPanel className="species-tab-panel" value={0}>{propsReady() ? <SpeciesTable filters={filters} /> : null}</TabPanel>
                                    <TabPanel className="species-tab-panel" value={1}>{propsReady() ? <Sankey filters={filters} /> : null}</TabPanel>
                                    <TabPanel className="species-tab-panel" value={2}>{propsReady() ? <PackedBubble filters={filters} /> : null}</TabPanel>
                                </TabsUnstyled>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
}



