import React from 'react'
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { darkFrostyGlass, brightFrostyGlass } from '../../style/textures'
import { Accordion, AccordionDetails, AccordionSummary, Button, MobileStepper } from '@material-ui/core';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';


const useStyles = makeStyles((theme) => ({

    body: {
        ...brightFrostyGlass,
        borderRadius: "10px",
        // border: '0.5px solid rgba(255, 255, 255, 0.1)',
        padding: "20px 50px",
        marginTop: "-40px",
        width: "80%",
        overflow: "hidden",
    },
    accordionPanel: {
        ...brightFrostyGlass,
        width: '100%',        
        marginBottom: '2px',
        boxSizing: 'border-box',
        borderRadius: '5px',
    },
    accordionDetails: {
        // height: "400px",
        // overflow: "hidden",
    },
    spTitle: {
        fontSize: theme.typography.pxToRem(20),
        flexBasis: '95%',
        flexShrink: 0,
        marginTop: 6,
        justifyContent: "center"
    },
    stepper: {
        ...brightFrostyGlass,
        borderRadius: '5px',
        marginTop: "-70px",
        marginBottom: "5px",
    },    
}));




export default function FeaturedSpecies({featuredSpecies}) {
    const classes = useStyles()
    const [expanded, setExpanded] = React.useState(false)
    const handleClick = () => {setExpanded(v=>!v)}

    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const { speciesName, embed, body, cite } = featuredSpecies[activeStep]
    return (
        <>
            <iframe src={embed} frameBorder="0" width="100%" height="400" scrolling="no"></iframe>
            {/* <div className={classes.body} > */}
                {/* <Typography variant="h6" align="left" style={{ marginBottom: "20px" }}> Featured Organism: </Typography>
                <Typography variant="h4" align="center" style={{ marginBottom: "30px" }}> {speciesName} </Typography>
                <Typography variant="body1" align="justify" style={{ marginBottom: "30px", wordSpacing: "-2px" }}> {body} </Typography>
                {cite.map((c, i) => <Typography component="div" key={i} variant="caption" align="left"> {c} </Typography>)} */}


            <MobileStepper
                variant="dots"
                steps={featuredSpecies.length}
                position="static"
                activeStep={activeStep}
                sx={{ maxWidth: 400, flexGrow: 1 }}
                className={classes.stepper}
                nextButton={
                    <Button size="small" onClick={handleNext} disabled={activeStep === featuredSpecies.length -1}>
                    Next
                    {theme.direction === 'rtl' ? (
                        <KeyboardArrowLeft />
                    ) : (
                        <KeyboardArrowRight />
                    )}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                    {theme.direction === 'rtl' ? (
                        <KeyboardArrowRight />
                    ) : (
                        <KeyboardArrowLeft />
                    )}
                    Back
                    </Button>
                }
                />
                

                <Accordion className={classes.accordionPanel} onClick={handleClick}>
                    <AccordionSummary
                        expandIcon={expanded ? <RemoveCircleIcon /> : <AddCircleIcon />}
                        aria-controls={`bh-content`}
                        id={`bh-header`}
                    >
                        <Typography className={classes.spTitle}> {`Featured: ${speciesName}`} </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>

                        <Typography variant="body1" align="justify" style={{ marginBottom: "30px", wordSpacing: "-2px" }}> {body} </Typography>
                        {/* {cite.map((c, i) => <Typography component="div" key={i} variant="caption" align="left"> {c} </Typography>)} */}

                    </AccordionDetails>
                </Accordion>


            {/* </div> */}
        </>
    )
}

