
import React, { useEffect, useMemo } from 'react'
import * as aq from 'arquero'
import './eda.css'
import { useDispatch, useSelector } from 'react-redux'
import { actDasboardStatStart } from '../../../redux/actions/actDashboard'
import StatGrid from './StatGrid'
import HistoScatter from '../figs/highcharts/spline/HistoScatter'
import ScatterPanel from './ScatterPanel'




function EDA({ data }) {
    const df = aq.from(data)
    const dispatch = useDispatch();
    const statReport = useSelector((state) => state.redDashboard.statReport)
    const showSpinner = useSelector((state) => state.redUI.showSpinner)
    useEffect(() => {
        dispatch(actDasboardStatStart(data))
    }, [data])
    

    const minMax = (data) => {
        const maxDepth = Number(data.reduce((a, b) => Number(a.depth) > Number(b.depth) ? a : b, -Infinity).depth);
        const minDepth = Number(data.reduce((a, b) => Number(a.depth) < Number(b.depth) ? a : b, Infinity).depth);

        const maxLat = Number(data.reduce((a, b) => Number(a.lat) > Number(b.lat) ? a : b, -Infinity).lat);
        const minLat = Number(data.reduce((a, b) => Number(a.lat) < Number(b.lat) ? a : b, Infinity).lat);

        const maxLon = Number(data.reduce((a, b) => Number(a.lon) > Number(b.lon) ? a : b, -Infinity).lon);
        const minLon = Number(data.reduce((a, b) => Number(a.lon) < Number(b.lon) ? a : b, Infinity).lon);
        return [Math.ceil(maxLat), Math.floor(minLat), Math.ceil(maxLon), Math.floor(minLon), Math.ceil(maxDepth), Math.floor(minDepth)]

    }

    const [maxLat, minLat, maxLon, minLon, maxDepth, minDepth] = useMemo(() => minMax(data), [data]);
    return (
        <div>

            <div className="eda-row">
                <HistoScatter hideScatter x={df.array('Abund_cells_per_ml')} y={df.array('Abund_cells_per_ml')} title="Compiled Cell Abundance Distribution" xlabel="Abundance [Cells/mL]" ylabel="" />
                <StatGrid statReport={statReport["Abund_cells_per_ml"]} />
            </div>

            <div className="eda-row">
                <HistoScatter hideScatter x={df.array('lat')} y={df.array('Abund_cells_per_ml')} title="" xlabel="Latitude [deg]" ylabel="Abundance [Cells/mL]" />
                <HistoScatter hideScatter x={df.array('lon')} y={df.array('Abund_cells_per_ml')} title="" xlabel="Longitude [deg]" ylabel="Abundance [Cells/mL]" />
            </div>

            {/* <div className="eda-row">
               <HistoScatter hideScatter x={df.array('depth')} y={df.array('Abund_cells_per_ml')} title="" xlabel="Depth [m]" ylabel="Abundance [Cells/mL]" />
               <HistoScatter hideScatter x={df.array('month')} y={df.array('Abund_cells_per_ml')} title="" xlabel="Month" ylabel="Abundance [Cells/mL]" />
           </div>  */}


            <div className="scatter-panel-row">
                <ScatterPanel
                    df={df} columns={df.columnNames()} 
                    MAX_LAT={maxLat} MIN_LAT={minLat} LAT_STEP={1}
                    MAX_LON={maxLon} MIN_LON={minLon} LON_STEP={1}
                    MAX_DEPTH={maxDepth} MIN_DEPTH={minDepth} DEPTH_STEP={5}
                />
            </div>

        </div>
    )
}

export default EDA
