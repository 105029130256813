import React from 'react';
import { Grid } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { fade, makeStyles } from '@material-ui/core/styles';
import { darkFrostyGlass } from '../../style/textures';




const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginTop: 0,
        marginRight: 5,
        padding: 5,
      },    
    doi: {        
        width: 25,
        verticalAlign: 'middle',
    },    
    smallMenuButton: {
        marginLeft: '0px',
        marginTop: 0,
        padding: 5,
      },   
    smallDOI: {        
        width: 15,
        verticalAlign: 'middle',
    },         
    accordionPanel: {
        ...darkFrostyGlass,  
        width: '100%',
        marginBottom: '2px',
        boxSizing: 'border-box',
        borderRadius: '5px',
    },
    pubTitle: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '95%',
        flexShrink: 0,
        marginTop: 6,
      },
      secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        marginTop: 6,
      },
      pubThumbnail: {
          maxWidth: 350,
      },
      divider: {
          marginTop: 10,
          marginBottom: 10,
          background: fade(theme.palette.common.white, 0.5)
      },
}));




export default function Publication(props) {
    const classes = useStyles();
    const {id, doi, title, abstract, thumbnail, citation, handleChange, expanded } = props;
    return (
        <Accordion className={classes.accordionPanel} expanded={expanded === id} onChange={handleChange(id)}>
        <AccordionSummary                     
        expandIcon={expanded === id ? <RemoveCircleIcon /> : <AddCircleIcon />}
        aria-controls={`${id}bh-content`}
        id={`${id}bh-header`}
        >

        <IconButton
        edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="publication doi link"
        target="_blank" 
        rel="noopener noreferrer"
        href={doi} 
        >                        
            <img className={classes.doi} src="/images/doi.png" alt="doi icon" />
        </IconButton>
        <Typography className={classes.pubTitle}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>

            <Grid container justify='center'>
                <Grid item xs={12} md={5} align='center'>
                    <img className={classes.pubThumbnail} src={thumbnail} alt="pub thumbnail" />                    
                </Grid>

                <Grid item xs={12} md={7}>
                    <Typography variant="h5"> Abstract </Typography>
                    <Typography variant="body2" align="justify">{abstract}</Typography>
                    <Divider className={classes.divider} />
                    <Typography variant="caption" align="justify">{citation}</Typography>
                    <IconButton
                    edge="start"
                    className={classes.smallMenuButton}
                    color="inherit"
                    aria-label="publication doi link"
                    target="_blank" 
                    rel="noopener noreferrer"
                    href={doi} 
                    >                        
                        <img className={classes.smallDOI} src="/images/doi.png" alt="doi icon" />
                    </IconButton>                                

                </Grid>
            </Grid>

        </AccordionDetails>
    </Accordion>    )
}
