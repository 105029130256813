import React, { useState, useRef, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { tocsv } from '../../utils/csv';
import Kepler from '../../components/dashboard/figs/kepler/Kepler'
import EDA from '../../components/dashboard/eda/EDA';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LoadingButton from '@mui/lab/LoadingButton';
import './dashboard.css'





function Dashboard() {
    const downRef = useRef(null);
    const [showEDA, setShowEDA] = useState(false)
    const [btnTextEDA, setBtnTextEDA] = useState('More Figures')
    const data = useSelector((state) => state.redDashboard.dashboardData)
    data.map(e => {
        e.year = Number(e['time'].split('-')[0]);
        e.month = Number(e['time'].split('-')[1])
    })

    const toggleShowEDA = () => {
        if (!data || !data.length > 0) { return }
        if (!showEDA) {
            setBtnTextEDA('Less Figures')
        } else {
            setBtnTextEDA('More Figures')
        }
        
        setShowEDA(pre => !pre)
    }

    useEffect(() => {
        downRef.current.scrollIntoView({ behavior: 'smooth' });
      });

    return (
        <div className='dashboard-root'>
            <Kepler csvData={tocsv(data)} />
            <LoadingButton
                onClick={() => (toggleShowEDA())}
                endIcon={<DashboardIcon />}
                loading={false}
                loadingPosition="end"
                variant="outlined"
                size="large"
                style={{ display: "flex", margin: '0 auto', marginTop: '20px', marginBottom: '20px' }}
            >
                {btnTextEDA}
            </LoadingButton>
            { showEDA && <EDA data={data} /> }
            <div ref={downRef} />
        </div>
    )
}

export default Dashboard
