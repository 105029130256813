import CSVParser from 'csv-parse';
import encoding from 'text-encoding';

const baseURI = 'https://simonscmap.com';
const options = {headers: {Authorization: `Api-Key ${process.env.REACT_APP_CMAP_API_KEY}`}};


export const query = async(queryStatement) => {
    // Submits a SQL query statement.
    // Retuns the results in form of a js object.
    
    const sp = new URLSearchParams({query: queryStatement, servername: 'rossby'})
    const uri = baseURI + '/api/data/query?' + sp.toString()

    let data = [];
    const decoder = new encoding.TextDecoder();
    let csvParser = CSVParser({columns:true});
    csvParser.on('readable', function(){
        let record;
        while (record = csvParser.read()) {data.push(record)}
    })    
    let response = await fetch(uri, options);
    if(!response.ok) return false;
    let body = response.body;
    let reader = body.getReader();
    let readerIsDone = false;
    while(!readerIsDone){
        let chunk = await reader.read();
        if(chunk.done) {
            readerIsDone = true;
        }
        else {
            csvParser.write(decoder.decode(chunk.value));
        };
    }
    return data;
}


