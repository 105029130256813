import React, {useState, useEffect} from 'react'
import Plot from 'react-plotly.js'
import * as aq from 'arquero'
import './sankey.css'
import { query } from '../../../../api/rest'
import useWindowSize from '../../../../utils/useWindowSize'


const Sankey = (props) => {
    const { height, width } = useWindowSize();
    const [snakeyData, setSnakeyData] = useState([])
    useEffect( async () => { 
        let where = [];
        let whereClause = '';
        if (props.filters.domain.length>0) {where.push(` Domain in (${props.filters.domain.map(i=>"'"+i.title+"'").join()})`)}
        if (props.filters.kingdom.length>0) {where.push(` Kingdom in (${props.filters.kingdom.map(i=>"'"+i.title+"'").join()})`)}
        if (props.filters.phylum.length>0) {where.push(` Phylum in (${props.filters.phylum.map(i=>"'"+i.title+"'").join()})`)}
        if (props.filters.Class.length>0) {where.push(` Class in (${props.filters.Class.map(i=>"'"+i.title+"'").join()})`)}
        if (props.filters.order.length>0) {where.push(` [Order] in (${props.filters.order.map(i=>"'"+i.title+"'").join()})`)}
        if (props.filters.family.length>0) {where.push(` Family in (${props.filters.family.map(i=>"'"+i.title+"'").join()})`)}
        if (props.filters.genus.length>0) {where.push(` Genus in (${props.filters.genus.map(i=>"'"+i.title+"'").join()})`)}
        if (props.filters.species.length>0) {where.push(` Species in (${props.filters.species.map(i=>"'"+i.title+"'").join()})`)}
        
        if (props.filters.trophic.length>0) {where.push(` Trophic_Level in (${props.filters.trophic.map(i=>"'"+i.title+"'").join()})`)}
        if (props.filters.subtrophic.length>0) {where.push(` SubTrophic_Level in (${props.filters.subtrophic.map(i=>"'"+i.title+"'").join()})`)}
        
        if (props.filters.functional.length>0) {where.push(` Functional_Group in (${props.filters.functional.map(i=>"'"+i.title+"'").join()})`)}
        if (props.filters.paraphyletic.length>0) {where.push(` Paraphyletic_Group in (${props.filters.paraphyletic.map(i=>"'"+i.title+"'").join()})`)}
        if (props.filters.unicell.length>0) {where.push(` Unicellularity in (${props.filters.unicell.map(i=>"'"+i.title+"'").join()})`)}


        if (where.length>0) {whereClause = ` where ${where.join(' and ')}`}
        const data = await query(`                                        
                                --select o.Organism_ID, o.[Name], v.Short_Name, v.Table_Name, isnull(t.Domain, 'Void_Domain') Domain, isnull(t.Kingdom, 'Void_Kingdom') Kingdom, isnull(t.Phylum, 'Void_Phylum') Phylum, isnull(t.Class, 'Void_Class') Class, isnull(t.[Order], 'Void_Order') [Order], isnull(t.Family, 'Void_Family') Family, isnull(t.Genus, 'Void_Genus') Genus, isnull(t.Species, 'Void_Species') Species from tblOrganism o 
                                --left join tblOrgTaxon t on o.Organism_ID=t.Org_ID 
                                --left join tblVariables v on o.Organism_ID=v.Org_ID 

                                select o.Organism_ID, o.[Name], v.Short_Name, v.Table_Name, isnull(t.Domain, 'Void_Domain') Domain, isnull(t.Kingdom, 'Void_Kingdom') Kingdom, isnull(t.Phylum, 'Void_Phylum') Phylum, isnull(t.Class, 'Void_Class') Class, isnull(t.[Order], 'Void_Order') [Order], isnull(t.Family, 'Void_Family') Family, isnull(t.Genus, 'Void_Genus') Genus, isnull(t.Species, 'Void_Species') Species 
                                ,ps.Trophic_Level, s.Subtrophic_Level, fun.Functional_Group, par.Paraphyletic_Group, uni.Unicellularity
                                from tblOrganism o 
                                left join tblOrgTaxon t on o.Organism_ID=t.Org_ID 
                                left join tblVariables v on o.Organism_ID=v.Org_ID 
                                left join tblOrgTrophic_Level p on p.Org_ID=o.Organism_ID
                                left join tblOrgTrophics ps on p.Trophic_ID=ps.Trophic_ID
                                left join tblOrgSubtrophics s on s.Subtrophic_ID=p.Subtrophic_ID
                                left join tblOrgFunctional_Group_WORMS fun on fun.Org_ID=o.Organism_ID
                                left join tblOrgParaphyletic_Group_WORMS par on par.Org_ID=o.Organism_ID
                                left join tblOrgUnicellularity_WORMS uni on uni.Org_ID=o.Organism_ID
                                ${whereClause}
                                `); 
        setSnakeyData(data);
    }, [props]);   


    let labels = []
    let prefixedLabels = []
    let unique = []
    const df = aq.from(snakeyData)
    const cols = ["Domain", "Kingdom", "Phylum", "Class", "Order", "Family", "Genus", "Species"]//, "Name"]
    for (let col of cols) {
        unique = new Set(df.array(col))
        labels.push(...unique)
        prefixedLabels.push(...[...unique].map((it)=>(`${col}_${it}`)))

    }    

    let source = []
    let target = [] 
    let value = []

    const valCount = (arr, val) => arr.reduce((a, v) => (v === val ? a + 1 : a), 0);
    for (let i = 0; i < df.numRows(); i++) {
        for (let c = 0; c < cols.length-1; c++) {
            source.push(prefixedLabels.indexOf(`${cols[c]}_${df.get(cols[c], i)}`))
            target.push(prefixedLabels.indexOf(`${cols[c+1]}_${df.get(cols[c+1], i)}`))            
            value.push(valCount(prefixedLabels, `${cols[c]}_${df.get(cols[c], i)}`))
        }
    }

    let config = {
                  displaylogo: false,
                  displayModeBar: false,
                  responsive: true,
                  scrollZoom: true,
                  editable: true,
                  showLink: false,
                  staticPlot: false,
                  toImageButtonOptions: {
                    format: 'png', // png, svg, jpeg, webp
                    filename: 'Taxonomy',
                    height: 800,
                    width: 1200,
                    scale: 5 
                  },  
                  modeBarButtonsToRemove: ['pan2d','select2d','lasso2d','resetScale2d','zoomOut2d'],
                }

    let data = {
        type: "sankey",
        orientation: "h",
        node: {
            pad: 15,
            thickness: 30,
            line: {
            color: "black",
            width: 0.5
            },
            label: labels,
            color: "blue"
            },
        
        link: {
            source: source,
            target: target,
            value:  value
        }
        }
        
        let layout = {
            // autosize: true,
            width: Math.floor(0.98*width),
            height: 1200,
            title: "",
            margin: {l:0, r:50, t:0, b:50},
            font: {
                size: 10
            },
            // paper_bgcolor: 'rgba(0,0,0,0)',
            // plot_bgcolor: 'rgba(0,0,0,0)',
            // modebar: {
            //     bgcolor: 'rgba(0,0,0,0)',
            //     color: 'rgba(0,0,0,1)'
            //     },

        }

        // if (width >= 1920) {
        // layout = {...layout,             
        //     width: Math.round(width/2),
        //     height: 1000,
        // }
    // }

    return (
        <div className="sankey-container">
            {/* <Typography variant="h3" align="center" alignItems="flex-end">Taxonomy Tree</Typography> */}
            <Plot data={[data]} layout={layout} config={config}/>       
        </div>
    )
}


export default Sankey;