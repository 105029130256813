import React from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highcharttsVariablePie from "highcharts/modules/variable-pie";
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsExportData from 'highcharts/modules/export-data'
import Boost from 'highcharts/modules/boost';


highcharttsVariablePie(Highcharts);
Boost(Highcharts);


if (typeof(Highcharts) === 'object') {
  HighchartsExporting(Highcharts);
  HighchartsExportData(Highcharts);
}



function Pie(props) {
    const chartOptions = {
        chart: {
            type: 'variablepie',
            backgroundColor: 'transparent',
            zoomType: 'xy',
            // height: 500
        },
        title: {
            text: props.title,
            // align: 'left'
        },
        credits: {
            enabled: false
        },
        exporting: {
            enabled: false
          },
        tooltip: {
            headerFormat: '',
            valueDecimals: 2,
            pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
              'Inference Confidence: <b>{point.y}</b>'
            //   + '<br/>z: <b>{point.z}</b><br/>'
          },
          series: [{
            minPointSize: 10,
            innerSize: '20%',
            zMin: 0,
            name: 'countries',
            data: props.probs.map((e, i)=> ({name: props.class_names[i], y: Number(e), z: 1}) ),

          }],

    }


    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
    )
}

export default React.memo(Pie)
