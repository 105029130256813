import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
// import WaveBackground from '../../components/ui/backgrounds/waveBackground';
import { Grid } from '@mui/material';
import FeaturedSpecies from './FeaturedSpecies';
import { serviceDescription, featuredSpecies } from './content';
import ServicesPanel from './ServicesPanel';
import LeftPanel from './LeftPanel';
import { Typography } from '@material-ui/core';
import bkg from '../../assets/others/20.jpeg';


const useStyles = makeStyles((theme) => ({
    contentWrapper: {
        maxWidth: "1234px",
        margin: "0 auto",
        padding: "200px 30px",
        display: "grid",
        gridTemplateColumns: "600px auto",
        gap: "30px",
        backgroundColor: 'blue',
    },
    root: {
        padding: "10px 20px",
        maxWidth: "1500px", 
        // marginLeft: "auto", 
        // marginRight: "auto"
    },
    rightPanel: {
        marginTop: "100px",
    },
    servicePanel: {
        marginTop: "50px",
        marginLeft: "auto",
        marginRight: "auto",
        width: "90%"
    },
}));




export default function Home() {
    const classes = useStyles()
    // useEffect(() => {
    //     document.body.style.overflow = 'hidden';
    //     return () => document.body.style.overflow = 'auto'
    // })


    return (
        <div style={{backgroundImage: `url(${bkg})`, width: "100%", height: "100vh", objectFit: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center center"}}>
            {/* <WaveBackground /> */}
            <div className={classes.root}>
                <Grid container spacing={3} >
                    <Grid item sm={12} md={8} >
                        <LeftPanel />
                    </Grid>

                    <Grid item sm={12} md={4}>
                        <Typography variant='body2' style={{ color:"black", marginTop: "70px", marginBottom: "-70px" }}>
                            Under Active Development - Last Update 03/16/2023 - V0.5.06
                        </Typography>
                        <div className={classes.rightPanel}>
                            <FeaturedSpecies featuredSpecies={featuredSpecies} />
                        </div>
                    </Grid>
                </Grid>

                <div className={classes.servicePanel} >
                    <ServicesPanel serviceDescription={serviceDescription} />                  
                </div>

                <div style={{position: "fixed", width:"100%", bottom:"0", left:"10px"}}>
                    <Typography variant='caption' style={{ fontSize: 8 }}>Image Credit:  www.freepik.com</Typography>
                </div>
            </div>
        </div>
    )
}
