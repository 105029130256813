import React from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsExportData from 'highcharts/modules/export-data'
import more from "highcharts/highcharts-more";
import draggable from "highcharts/modules/draggable-points";
import Boost from 'highcharts/modules/boost';

Boost(Highcharts);


if (typeof(Highcharts) === 'object') {
  HighchartsExporting(Highcharts);
  HighchartsExportData(Highcharts);
  more(Highcharts);
  draggable(Highcharts);
}

function AreaRangePlot(props) {
    props = props.plot

    const chartOptions = {

        chart: {
            zoomType: 'xy',
            height: 320
        },  
        credits: {
            enabled: false
        },      
        title: {
            text: null
          },
        
          xAxis: {
            type: 'category',
            title: {
                text: props.xlabel
              }

          },
        
          yAxis: {
            title: {
              text: props.ylabel
            }
          },
        
          tooltip: {
            crosshairs: true,
            shared: true,

          },
        
          series: [

              {
            name: 'Prediction Error',
            data: props.data,
            zIndex: 1,
            marker: {
              fillColor: 'white',
              lineWidth: 2,
              lineColor: Highcharts.getOptions().colors[0]
            }
          }, 
          
          {
            name: 'Range',
            data: props.dataRange,
            type: 'arearange',
            lineWidth: 0,
            linkedTo: ':previous',
            color: Highcharts.getOptions().colors[0],
            fillOpacity: 0.3,
            zIndex: 0,
            marker: {
              enabled: false
            }
          }
        ]
    }



    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
    )
}

export default React.memo(AreaRangePlot)
