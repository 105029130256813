

import { all } from 'redux-saga/effects';
import * as sagDashboard from './sagDashboard';




export default function* rootSaga() {
    yield all([
        sagDashboard.watchStatCalc(),
    ]);
}