
import * as Statistics from 'statistics.js'




export default function stats(data) {
    if (!data || !data.length>0) { return {} }
    let columns = {};

    for (const property in data[0]) {
        if (property === 'time') { continue }
        columns = {
            ...columns,
            [`${property}`]: (!isNaN(parseFloat(data[0][property])) && isFinite(data[0][property])) ? 'metric' : 'nominal'
        }
    }

    let settings = {
        excludeColumns: ['time'],
        suppressWarnings: false,
    };

    const count = data.length    
    var stats = new Statistics(data, columns, settings);
    let statReport = {}
    const dispLimit = 5e4
    const shapeLimit = 3e4
    for (const property in data[0]) {
        if (columns[`${property}`] !== 'metric') {continue}

        if (property !== 'Abund_cells_per_ml') {continue}

        statReport = {
            ...statReport,
            [`${property}`]: {
            count,
            distinct: count<dispLimit ? (new Set(data.map(e=>e[property]))).size : null,
            max: count<dispLimit ? stats.maximum(`${property}`) : null,
            q3: count<dispLimit ? stats.quantile(`${property}`, 0.75) : null,
            median: count<dispLimit ? stats.median(`${property}`) : null,
            mean: count<dispLimit ? stats.arithmeticMean(`${property}`) : null,
            q1: count<dispLimit ? stats.quantile(`${property}`, 0.25) : null,
            min: count<dispLimit ? stats.minimum(`${property}`) : null,
            std: count<dispLimit ? stats.standardDeviation(`${property}`) : null,
            kurtosis: count<shapeLimit ? stats.kurtosis(`${property}`) : null,
            skewness: count<shapeLimit ? stats.skewness(`${property}`) : null,
        }}
    }
    return statReport
}





