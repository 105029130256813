import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import React from 'react'
import CNNInference from '../../components/ml/CNNInference'



const useStyles = makeStyles((theme) => ({
    root: {
        margin: "10px",
    },
    sectionTitle: {
        fontSize:"2rem",
        marginBottom:"10px",
    }

}));


function CNN() {
    const classes = useStyles()
  return (
    <div className={classes.root}>
        <Box display={"flex"} justifyContent="left">
            <Typography variant="h2" className={classes.sectionTitle}>
                Plankton Image Classification
            </Typography>    
        </Box>
        <CNNInference/>   
    </div>
  )
}

export default CNN