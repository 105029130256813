
import * as dashboardActionTypes from '../actionTypes/typDashboard';




export const actDasboardData= (data) => ({
    type: dashboardActionTypes.DASHBOARD_DATA,
    payload: {
        data
    }
});




/////////  dasboard stat actions   /////////
export const actDasboardStatStart = (data) => ({
    // sagas action
    type: dashboardActionTypes.STAT_CLAC_START,
    payload: {
        data
    }
});

export const actDasboardStatSuccess = (statReport) => ({ 
    type: dashboardActionTypes.STAT_CALC_SUCCESS, 
    payload: {statReport} 
});

export const actDasboardStatFailure = () => ({ 
    type: dashboardActionTypes.STAT_CLAC_FAILURE 
});