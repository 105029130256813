import React from "react";
import { styled } from "@material-ui/core/styles";
// import wave11SVG from "../../../assets/waves/hero-wave1.svg";
import wave1SVG from "../../../assets/waves/livestream-wave1.svg";
import wave2SVG from "../../../assets/waves/livestream-wave2.svg";
import wave3SVG from "../../../assets/waves/livestream-wave3.svg";
import wave4SVG from "../../../assets/waves/livestream-wave4.svg";
import wave5SVG from "../../../assets/waves/livestream-wave5.svg";
import wave6SVG from "../../../assets/waves/livestream-wave6.svg";

export default function WaveBackground2() {
  return (
    <Wrapper>
      <Background />
      <Wave src={wave1SVG} style={{ top: "50px" }} />
      <Wave src={wave2SVG} style={{ top: "100px" }} />
      <Wave src={wave3SVG} style={{ top: "150px" }} />
      <Wave src={wave4SVG} style={{ top: "200px" }} />
      <Wave src={wave5SVG} style={{ top: "350px" }} />
      <Wave src={wave6SVG} style={{ top: "950px" }} />
    </Wrapper>
  );
}

const Wrapper = styled('div')({
  position: 'relative'
});

const Wave = styled('img')({
  position: 'absolute',
  left: 0,
  width: '100%',
  zIndex: -1,
});

const Background = styled('div')({
  background: 'linear-gradient(180deg, #176ab1 17.01%, #cbd8f1 100%)',
  position: 'absolute',
  width: '100%',
  height: '800px',
  left: '0px',
  top: '0px',
  zIndex: -1,
});
