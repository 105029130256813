import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import KeplerGl from 'kepler.gl';
import { updateMap, addDataToMap } from 'kepler.gl/actions';
import Processors from 'kepler.gl/processors';
import useWindowSize from '../../../../utils/useWindowSize';


import KeplerGlSchema from 'kepler.gl/schemas';
import { visStateUpdaters } from 'kepler.gl/reducers';


const Kepler = props => {
  const { height, width } = useWindowSize();
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(updateMap({
      latitude: 20,
      longitude: -155,
      // bearing: 0,
      // dragRotate: true,
      // pitch: 0,
      zoom: 2,
      // isSplit: true
    }));

  }, [])



  const loadKepler = (csvText) => {
    const dataset = {
      data: Processors.processCsvData(csvText),
      info: {
        label: 'compiled-species',
        id: 'compiled-species'
      },
      option: {
        centerMap: true,
        readOnly: false
      },
    };
    dispatch(addDataToMap({ datasets: dataset }))
  }

  useEffect(() => {
    if (props.csvData) { loadKepler(props.csvData) }


    console.log(KeplerGl)
    // const configToSave = KeplerGlSchema.getConfigToSave(KeplerGl.map);
    // console.log('configToSave', configToSave);
    // var layerConfigs = configToSave.config.visState.layers;

    // console.log('>>>>>>>>>>>>>>>>>')
    // console.log(layerConfigs)
    
    // // Removed each uploaded layers
    // if (configToSave && layerConfigs.length > 0) {
    //     for (var i = 0; i < layerConfigs.length; i++) {
    //         this.props.demo.keplerGl.map.visState = 
    //               visStateUpdaters.removeDatasetUpdater(            
    //               this.props.demo.keplerGl.map.visState,
    //             { key: dataToSave[i].data.id }
    //          )
    //        }
    //      }
          



  }, [props.csvData])


  //  const theme = {
  //       sidePanelBg: '#ffffff',
  //       titleTextColor: '#000000',
  //       sidePanelHeaderBg: '#f7f7F7',
  //  }

 
  return (
    <div style={{ marginTop: '55px' }}>
      <KeplerGl
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_API_KEY}
        id="kepler"
        theme={"light"}
        width={width}
        height={Math.round(6*height/7)}
      />
    </div>
  )
};


export default Kepler;


