import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  gridClasses,
} from '@mui/x-data-grid';
import { Tooltip } from '@mui/material';
import './speciesTable.css';
import { query } from '../../../api/rest';
import { tocsv } from '../../../utils/csv';
import LoadingButton from '@mui/lab/LoadingButton';
import DownloadIcon from '@mui/icons-material/Download';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Card, CardActions, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import IconButton from '@mui/material/IconButton';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useHistory } from 'react-router-dom';
import { actDasboardData } from '../../../redux/actions/actDashboard'
import LabeledSwitch from '../../ui/labeled-switch/LabeledSwitch';




const useStyles = makeStyles({
  progressBar: {
    opacity: props => props.compiling ? '100' : '0',
  },
});

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


function CustomToolbar() {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer}>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport csvOptions={{ fileName: 'Species' }} />
    </GridToolbarContainer>
  );
}



const exportCSV = (filename, csvText) => {
  var blob = new Blob([csvText], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement("a");
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}


async function hasField(table, field) {
  const res = await query(`SELECT COL_LENGTH('${table}', '${field}') AS RESULT`)
  return !!res[0].RESULT
}


const countUniques = (arr) => (new Set(arr).size)

export default function SpeciesTable(props) {
  const [pageSize, setPageSize] = React.useState(50);
  const [species, setSpecies] = useState([])
  const [compiledData, setCompiledData] = useState([])
  const [compiling, setCompiling] = useState(false)
  const [ancillary, setAncillary] = useState(false)
  const [compileProgress, setCompileProgress] = useState(0)
  const classes = useStyles({ compiling });

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(async () => {
    let where = [];
    let whereClause = '';
    if (props.filters.domain.length > 0) { where.push(` Domain in (${props.filters.domain.map(i => "'" + i.title + "'").join()})`) }
    if (props.filters.kingdom.length > 0) { where.push(` Kingdom in (${props.filters.kingdom.map(i => "'" + i.title + "'").join()})`) }
    if (props.filters.phylum.length > 0) { where.push(` Phylum in (${props.filters.phylum.map(i => "'" + i.title + "'").join()})`) }
    if (props.filters.Class.length > 0) { where.push(` Class in (${props.filters.Class.map(i => "'" + i.title + "'").join()})`) }
    if (props.filters.order.length > 0) { where.push(` [Order] in (${props.filters.order.map(i => "'" + i.title + "'").join()})`) }
    if (props.filters.family.length > 0) { where.push(` Family in (${props.filters.family.map(i => "'" + i.title + "'").join()})`) }
    if (props.filters.genus.length > 0) { where.push(` Genus in (${props.filters.genus.map(i => "'" + i.title + "'").join()})`) }
    if (props.filters.species.length > 0) { where.push(` Species in (${props.filters.species.map(i => "'" + i.title + "'").join()})`) }

    if (props.filters.trophic.length > 0) { where.push(` Trophic_Level in (${props.filters.trophic.map(i => "'" + i.title + "'").join()})`) }
    if (props.filters.subtrophic.length > 0) { where.push(` SubTrophic_Level in (${props.filters.subtrophic.map(i => "'" + i.title + "'").join()})`) }

    if (props.filters.functional.length > 0) { where.push(` Functional_Group in (${props.filters.functional.map(i => "'" + i.title + "'").join()})`) }
    if (props.filters.paraphyletic.length > 0) { where.push(` Paraphyletic_Group in (${props.filters.paraphyletic.map(i => "'" + i.title + "'").join()})`) }
    if (props.filters.unicell.length > 0) { where.push(` Unicellularity in (${props.filters.unicell.map(i => "'" + i.title + "'").join()})`) }

    if (where.length > 0) { whereClause = ` where ${where.join(' and ')}` }
    const data = await query(`                                        
                              select o.Organism_ID, o.[Name], v.Table_Name, v.Short_Name, v.Unit,                               
                              t.Domain, t.Kingdom, t.Phylum, t.Class, t.[Order], t.Family, t.Genus, t.Species, t.Reference Taxonomy_Reference, t.Ref_Date Taxonomy_Reference_Date,
                              ps.Trophic_Level, sps.Subtrophic_Level, p.Reference Trophic_Reference, fun.Functional_Group, par.Paraphyletic_Group, uni.Unicellularity
                              , big.Cell_Min_Length, big.Cell_Max_Length, big.Cell_Min_Width, big.Cell_Max_Width, Known_Temperature_Range_Min, Known_Temperature_Range_Max
                              from tblOrganism o 
                              left join tblOrgTaxon t on o.Organism_ID=t.Org_ID 
                              left join tblVariables v on o.Organism_ID=v.Org_ID 
                              left join tblOrgTrophic_Level p on o.Organism_ID=p.Org_ID 
                              left join tblOrgTrophics ps on p.Trophic_ID=ps.Trophic_ID 
                              left join tblOrgSubtrophics sps on (p.Trophic_ID=sps.Trophic_ID and p.Subtrophic_ID=sps.Subtrophic_ID )
                              left join tblOrgFunctional_Group_WORMS fun on fun.Org_ID=o.Organism_ID
                              left join tblOrgParaphyletic_Group_WORMS par on par.Org_ID=o.Organism_ID
                              left join tblOrgUnicellularity_WORMS uni on uni.Org_ID=o.Organism_ID
                              left join (
                                          select Org_ID, 
                                                  Min(Big_Cell_Length_Min) Cell_Min_Length, 
                                                  Max(Big_Cell_Length_Max) Cell_Max_Length,
                                                  Min(Big_Cell_Width_Min) Cell_Min_Width, 
                                                  Max(Big_Cell_Width_Max) Cell_Max_Width,
                                                  Min(Big_Known_Temperature_Range_Start) Known_Temperature_Range_Min, 
                                                  Max(Big_Known_Temperature_Range_End) Known_Temperature_Range_Max                                                                                                          
                                                  from tblOrgSize_Image_Bigelow group by Org_ID
                                          ) big on big.Org_ID=o.Organism_ID
                              ${whereClause}
                              order by o.Organism_ID
                              `);
    setSpecies(data);
  }, [props]);

  const rows = species.map((d, i) => ({ id: i, ...d }))
  const columns = [
    { field: 'Organism_ID', headerName: 'Species ID', width: 100, hide: true },
    { field: 'Name', headerName: 'Species Name', width: 150, hide: false },
    { field: 'Table_Name', headerName: 'Table Name', width: 150 },
    { field: 'Short_Name', headerName: 'Variable Name', width: 150 },
    { field: 'Unit', headerName: 'Unit', width: 150 },

    { field: 'Functional_Group', headerName: 'Functional Group', width: 150, hide: false },
    { field: 'Paraphyletic_Group', headerName: 'Paraphyletic Group', width: 150, hide: false },
    { field: 'Unicellularity', headerName: 'Unicellularity', width: 150, hide: true },

    { field: 'Cell_Min_Length', headerName: 'Length Min (μm)', width: 150, hide: false },
    { field: 'Cell_Max_Length', headerName: 'Length Max (μm)', width: 150, hide: false },
    { field: 'Cell_Min_Width', headerName: 'Width Min (μm)', width: 150, hide: false },
    { field: 'Cell_Max_Width', headerName: 'Width Max (μm)', width: 150, hide: false },

    { field: 'Known_Temperature_Range_Min', headerName: 'Known Temperature Min (°C)', width: 150, hide: false },
    { field: 'Known_Temperature_Range_Max', headerName: 'Known Temperature Max (°C)', width: 150, hide: false },

    { field: 'Domain', headerName: 'Domain', width: 150 },
    { field: 'Kingdom', headerName: 'Kingdom', width: 150 },
    { field: 'Phylum', headerName: 'Phylum', width: 150 },
    { field: 'Class', headerName: 'Class', width: 150 },
    { field: 'Order', headerName: 'Order', width: 150 },
    { field: 'Family', headerName: 'Family', width: 150 },
    { field: 'Genus', headerName: 'Genus', width: 150 },
    { field: 'Species', headerName: 'Species', width: 150, hide: true },
    {
      field: 'Taxonomy_Reference', headerName: 'Taxonomy Reference', width: 150, hide: true, renderCell: (data) => (
        <Tooltip title={data.row.Taxonomy_Reference} >
          <span className="trucate-cell">{data.row.Taxonomy_Reference}</span>
        </Tooltip>
      ),
    },
    { field: 'Taxonomy_Ref_Date', headerName: 'Taxonomy Reference Date', width: 150, hide: true },
    { field: 'Trophic_Level', headerName: 'Trophic Level', width: 150 },
    { field: 'Subtrophic_Level', headerName: 'SubTrophic Level', width: 150 },
    {
      field: 'Trophic_Reference', headerName: 'Trophic Reference', width: 150, hide: true, renderCell: (data) => (
        <Tooltip title={data.row.Trophic_Reference} >
          <span className="trucate-cell">{data.row.Trophic_Reference}</span>
        </Tooltip>
      ),
    },
  ];




  const compile = async (species, ancillary) => {
    let tables = [];
    let variables = [];
    let depthSel = '';
    setCompiling(true);
    setCompileProgress(0);
    setCompiledData([]);
    let queries = [];
    for (let sp of species) {
      if (tables.includes(sp.Table_Name) && variables.includes(sp.Short_Name)) { continue }
      tables.push(sp.Table_Name)
      variables.push(sp.Short_Name)
      depthSel = ` '' depth`
      if (!ancillary) {
        if (await hasField(sp.Table_Name, 'depth')) { depthSel = ' depth' }
        queries.push(`
                    select [time], lat, lon,${depthSel}, '${sp.Name}' Species_Name, '${sp.Table_Name}' Table_Name, '${sp.Short_Name}' Variable, 
                    ${sp.Short_Name} * (select Conversion_Coefficient from tblVariables where Table_Name='${sp.Table_Name}' and Short_Name='${sp.Short_Name}') Abund_cells_per_ml
                    --, '${sp.Unit}' Unit
                    from ${sp.Table_Name} 
                    where ${sp.Short_Name} is not null
                    `)
      } else {
        if (await hasField(sp.Table_Name, 'depth')) { depthSel = ' t1.depth' }
        queries.push(`
                    select '${sp.Name}' Species_Name, '${sp.Table_Name}' Table_Name, '${sp.Short_Name}' Variable, 
                    ${sp.Short_Name} * (select Conversion_Coefficient from tblVariables where Table_Name='${sp.Table_Name}' and Short_Name='${sp.Short_Name}') Abund_cells_per_ml
                    , t2.*
                    from ${sp.Table_Name} t1 
                    left join tblAncillary t2 on t1.[time]=t2.[time] and abs(t1.lat-t2.lat)<0.0001 and abs(t1.lon-t2.lon)<0.0001 and abs(t1.depth-t2.depth)<0.001
                    where 
                    ${sp.Short_Name} is not null
                    and t2.link='${sp.Table_Name}'
                    `)
      }
    }

    let results = []
    await Promise.all(queries.map(async q => {
      let res = await query(q);
      results.push(res);
      if (results.length == queries.length || results.length % 5 == 0) { setCompileProgress(Math.round(100 * results.length / queries.length)) }
      return res
    }));



    results = results.filter(e => e)
    results = [].concat.apply([], results)
    setCompiledData(results);
    dispatch(actDasboardData(results))
    if (results) { exportCSV('compiled-species.csv', tocsv(results)); }
    setCompiling(false)
  }

  const toggleAncillar = () => { setAncillary(a => !a) }
  return (
    <div className="table-wrapper">
      <Grid container justify="space-between" alignItems="flex-end">
        {/* <Typography display="inline" variant="h3" gutterBottom component="span" align="left">Species Observations</Typography> */}
        <Typography display="inline" variant="h5" gutterBottom component="span" align="right">
          {countUniques(species.map(s => s.Name))} Unique Species From {countUniques(species.map(s => s.Table_Name))} Datasets
        </Typography>
      </Grid>

      <DataGrid
        className="species-table"
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[25, 50, 100]}
        pagination
        components={{
          Toolbar: CustomToolbar,
        }}
      />


      <BorderLinearProgress variant="determinate"
        value={compileProgress}
        sx={{ '.MuiLinearProgress-bar': { transition: 'none' } }}
        className={classes.progressBar}
      />


      <Card sx={{ minWidth: 275 }} style={{ height: '150px' }}>
        <CardActions>
          <LoadingButton
            onClick={() => { compile(species, ancillary) }}
            endIcon={<DownloadIcon />}
            loading={compiling}
            loadingPosition="end"
            variant="outlined"
            size="large"
            style={{ display: "flex", margin: '0 auto', marginTop: '20px', marginBottom: '20px' }}
          >
          Compile & Unify Datasets
          </LoadingButton>
          <IconButton aria-label="dashboard" disabled={compiledData.length === 0} onClick={() => history.push('/dashboard')}>
            <DashboardIcon />
          </IconButton>
          <LabeledSwitch checked={ancillary} label={"Ancillary Data"} onClickHandler={toggleAncillar} />
        </CardActions>
      </Card>
    </div>
  );
}