import React from 'react'
import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles({
    switch_track: {
        backgroundColor: "#9F9F9F",
    },
    switch_base: {
        color: "#9F9F9F",
        "&.Mui-disabled": {
            color: "#e886a9"
        },
        "&.Mui-checked": {
            color: "#027FFE"
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#027FFE",
        }
    },
    switch_primary: {
        "&.Mui-checked": {
            color: "#027FFE",
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#027FFE",
        },
    },

});



function LabeledSwitch({ checked, label, onClickHandler }) {
    const classes = useStyles();
    return (
        <FormGroup>
            <FormControlLabel control={
                <Switch classes={{
                    track: classes.switch_track,
                    switchBase: classes.switch_base,
                    colorPrimary: classes.switch_primary
                }}
                    checked={checked}
                    onClick={onClickHandler}
                />
            } label={label} />
        </FormGroup>
    )
}

export default LabeledSwitch
