export const pubs = [

    {               
    doi : "https://doi.org/10.1002/lom3.10439",    
    title : "Simons Collaborative Marine Atlas Project (Simons CMAP): An open-source portal to share, visualize, and analyze ocean data",    
    abstract : "Simons Collaborative Marine Atlas Project (Simons CMAP) is an open-source data portal that interconnects large, complex, and diverse public data sets currently dispersed in different formats across different Oceanography discipline-specific databases. Simons CMAP is designed to streamline the retrieval of custom subsets of data, the generation of data visualizations, and the analyses of diverse data, thus expanding the power of these potentially underutilized data sets for cross-disciplinary studies of ocean processes. We describe a unified architecture that allows numerical model outputs, satellite products, and field observations to be readily shared, mined, and integrated regardless of data set size or resolution. A current focus of Simons CMAP is integration of physical, chemical, and biological data sets essential for characterizing the biogeography of key marine microbes across ocean basins and seasonal cycles. Using a practical example, we demonstrate how our unifying data harmonization plans significantly simplifies and allows for systematic data integration across all Simons CMAP data sets.",
    thumbnail : "/images/pub_mda.png",
    citation : "Mohammad D. Ashkezari, Norland R. Hagen, Michael Denholtz, Andrew Neang, Tansy C. Burns, Rhonda L. Morales, Charlotte P. Lee, Christopher N. Hill, E. Virginia Armbrust. Limnology and Oceanography: Methods",
    },
    {               
    doi : "https://doi.org/10.1002/2016GL071269",    
    title : "Oceanic eddy detection and lifetime forecast using machine learning methods",    
    abstract : "We report a novel altimetry-based machine learning approach for eddy identification and characterization. The machine learning models use daily maps of geostrophic velocity anomalies and are trained according to the phase angle between the zonal and meridional components at each grid point. The trained models are then used to identify the corresponding eddy phase patterns and to predict the lifetime of a detected eddy structure. The performance of the proposed method is examined at two dynamically different regions to demonstrate its robust behavior and region independency.",
    thumbnail : "/images/eddyML.png",
    citation : "Mohammad D. Ashkezari, Christopher N. Hill,Christopher N. Follett, Gaël Forget, Michael J. Follows. Geophysical Research Letters",
    },
    {               
    doi : "https://doi.org/10.1111/1462-2920.14628",    
    title : "Phosphate-limited ocean regions select for bacterial populations enriched in the carbon-phosphorus lyase pathway for phosphonate degradation",    
    abstract : "In tropical and subtropical oceanic surface waters phosphate scarcity can limit microbial productivity. However, these environments also have bioavailable forms of phosphorus incorporated into dissolved organic matter (DOM) that microbes with the necessary transport and hydrolysis metabolic pathways can access to supplement their phosphorus requirements. In this study we evaluated how the environment shapes the abundance and taxonomic distribution of the bacterial carbon-phosphorus (C-P) lyase pathway, an enzyme complex evolved to extract phosphate from phosphonates. Phosphonates are organophosphorus compounds characterized by a highly stable C-P bond and are enriched in marine DOM. Similar to other known bacterial adaptions to low phosphate environments, C-P lyase was found to become more prevalent as phosphate concentrations decreased. C-P lyase was particularly enriched in the Mediterranean Sea and North Atlantic Ocean, two regions that feature sustained periods of phosphate depletion. In these regions, C-P lyase was prevalent in several lineages of Alphaproteobacteria (Pelagibacter, SAR116, Roseobacter and Rhodospirillales), Gammaproteobacteria, and Actinobacteria. The global scope of this analysis supports previous studies that infer phosphonate catabolism via C-P lyase is an important adaptive strategy implemented by bacteria to alleviate phosphate limitation and expands the known geographic extent and taxonomic affiliation of this metabolic pathway in the ocean.",
    thumbnail : "/images/pub_os.png",
    citation : "Oscar A Sosa, Daniel J Repeta, Edward F DeLong, Mohammad D Ashkezari, David M Karl. Environmental Microbiology.",
    },
    {               
        doi : "https://doi.org/10.1101/2021.01.15.426851",    
        title : "The dynamic trophic architecture of open-ocean protist communities revealed through machine-guided metatranscriptomics",    
        abstract : "Intricate networks of single-celled eukaryotes (protists) dominate carbon flow in the ocean. Their growth, demise, and interactions with other microorganisms drive the fluxes of biogeochemical elements through marine ecosystems. Mixotrophic protists are capable of both photosynthesis and ingestion of prey and are dominant components of open-ocean planktonic communities. Yet, the role of mixotrophs in elemental cycling is obscured by their capacity to act as primary producers or heterotrophic consumers depending on factors that remain largely uncharacterized. Here we introduce a machine learning model that can predict the in situ nutritional mode of aquatic protists based on their patterns of gene expression. This approach leverages a public collection of protist transcriptomes as a training set to identify a subset of gene families whose transcriptional profiles predict trophic status. We applied our model to nearly 100 metatranscriptomes obtained during two oceanographic cruises in the North Pacific and found community-level and population-specific evidence that abundant open-ocean mixotrophic populations shift their predominant mode of nutrient and carbon acquisition in response to natural gradients in nutrient supply and sea surface temperature. In addition, metatranscriptomic data from ship-board incubation experiments revealed that abundant mixotrophic prymnesiophytes from the oligotrophic North Pacific subtropical gyre rapidly remodelled their transcriptome to enhance photosynthesis when supplied with limiting nutrients. Coupling the technique introduced here with experiments designed to reveal the mechanisms driving mixotroph physiology is a promising approach for understanding the ecology of mixotrophic populations in the natural environment.",
        thumbnail : "/images/pub_bsl.jpg",
        citation : "B.S. Lambert, R.D. Groussman, M.J. Schatz, S.N. Coesel, B.P. Durham, A.J. Alverson, A.E. White, E.V. Armbrust.",
    },
    {               
    doi : "https://arxiv.org/abs/2008.11251",    
    title : "Modeling Cell Populations Measured By Flow Cytometry With Covariates Using Sparse Mixture of Regressions",    
    abstract : "The ocean is filled with microscopic microalgae called phytoplankton, which together are responsible for as much photosynthesis as all plants on land combined. Our ability to predict their response to the warming ocean relies on understanding how the dynamics of phytoplankton populations is influenced by changes in environmental conditions. One powerful technique to study the dynamics of phytoplankton is flow cytometry, which measures the optical properties of thousands of individual cells per second. Today, oceanographers are able to collect flow cytometry data in real-time onboard a moving ship, providing them with fine-scale resolution of the distribution of phytoplankton across thousands of kilometers. One of the current challenges is to understand how these small and large scale variations relate to environmental conditions, such as nutrient availability, temperature, light and ocean currents. In this paper, we propose a novel sparse mixture of multivariate regressions model to estimate the time-varying phytoplankton subpopulations while simultaneously identifying the specific environmental covariates that are predictive of the observed changes to these subpopulations. We demonstrate the usefulness and interpretability of the approach using both synthetic data and real observations collected on an oceanographic cruise conducted in the north-east Pacific in the spring of 2017.",
    thumbnail : "/images/pub_sh.jpg",
    citation : "Sangwon Hyun, Mattias Rolf Cape, Francois Ribalet, Jacob Bien",
    },
   ];