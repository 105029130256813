

import * as uiActionTypes from '../actionTypes/typUI';



const INIT_STATE = {        
    showSpinner: false,
};



export default function uiReducer(state=INIT_STATE, action) {
    switch (action.type) {

        case uiActionTypes.SHOW_SPINNER: 
            return {
                ...state,
                showSpinner: action.payload.showSpinner
            }         

        default: return state;    
    }
}; 
