import { Typography } from '@material-ui/core';
import React from 'react';
import AreaRangePlot from './AreaRangePlot';
import './mlCell.css'


function MLCell({ title, videoLink, plot, caption }) {
    return (
        <div className='ml-cell-root'>
            {title.length>0 ?   
            <div className='ml-cell-title'>
                <Typography variant='h6' align='center'>{title}</Typography>
            </div>
            :
            null
}

            <div className='ml-cell-body'>
                {videoLink
                    ?
                    <div style={{ height: "350px" }}>
                        <iframe src={videoLink} frameBorder="0" width="100%" height="100%" scrolling="no" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                    :
                    <AreaRangePlot plot={plot} />
                }
            </div>

            {caption.length>0 ?        
            <div className='ml-cell-caption'>
                <Typography variant='body1' align='center'>{caption}</Typography>
            </div>
            :
            null
            }
        </div>
    )

}



export default MLCell;
