import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { darkFrostyGlass, brightFrostyGlass } from '../../style/textures'
import { Grid } from '@mui/material';

import { MdAreaChart } from "react-icons/md";
import { IoLayers } from "react-icons/io5";
import { BiNetworkChart } from "react-icons/bi";
import { Box } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({

    body: {
        ...brightFrostyGlass,
        borderRadius: "7px",
        padding: "20px 20px",
        maxHeight: "350px",
        minHeight: "200px",
        overflow: "hidden",
    },
}));


function ServicesPanel({ serviceDescription }) {
    const classes = useStyles()

    return (
        <Grid container spacing={3}>
            <Grid item sm={12} md={4}>
                <div className={classes.body} >
                    <Box display="flex" justifyContent="center">
                        <IoLayers style={{width:70, height:70}}/>
                    </Box>
                    <Typography variant="h6" align="center" style={{ marginBottom: "20px" }}> {serviceDescription[0].title} </Typography>
                    <Typography variant="body1" align="justify" style={{ marginBottom: "30px" }}> {serviceDescription[0].body} </Typography>
                </div>
            </Grid>


            <Grid item sm={12} md={4}>
                <div className={classes.body} >
                    <Box display="flex" justifyContent="center">
                        <BiNetworkChart style={{width:70, height:70}}/>
                    </Box>
                    <Typography variant="h6" align="center" style={{ marginBottom: "20px" }}> {serviceDescription[1].title} </Typography>
                    <Typography variant="body1" align="justify" style={{ marginBottom: "30px" }}> {serviceDescription[1].body} </Typography>
                </div>
            </Grid>


            <Grid item sm={12} md={4}>
                <div className={classes.body} >
                    <Box display="flex" justifyContent="center">
                        <MdAreaChart style={{width:70, height:70}}/>
                    </Box>
                    <Typography variant="h6" align="center" style={{ marginBottom: "20px" }}> {serviceDescription[2].title} </Typography>
                    <Typography variant="body1" align="justify" style={{ marginBottom: "30px" }}> {serviceDescription[2].body} </Typography>
                </div>
            </Grid>




        </Grid>
    )
}

export default ServicesPanel;
