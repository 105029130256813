import React from 'react'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import WaveBackground2 from '../../components/ui/backgrounds/waveBackground2'
import { darkFrostyGlass } from '../../style/textures'
import Sunburst from '../cmapLand/Sunburst'
import { Grid } from '@mui/material'
import worms from '../../assets/others/worms_logo.png'
import cmapGraph from '../../assets/others/graph.png'

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        overflow: 'hidden',
    },
    allContents: {
        marginTop: 100,

    },
    mission: {
        ...darkFrostyGlass,
        marginTop: 20,
        padding: 40,
        maxHeight: 700,
        overflow: 'auto'
    },
}));


const textContent = {
    intro: `
    My name is Mohammad D. Ashkezari (mdehghan@uw.edu), the author of "Three Quarters" and the lead author of "Simons CMAP" projects.
    I'm a Physics PhD with interests in data science, machine learning, and software development. 
    `,

    body: `
    The Three Quarters project is focused on the study of marine micro-organisms. 
    The goal is to develop machine learning algorithms to model the spatio-temporal variabilities of the organism abundances. 
    To achieve this goal, Three Quarters identifies and compiles archive measurements of species cell abundances. The collected data is then
    integrated with contemporaneous environmental variables, e.g. temperature data by satellites, to provide more context (covariates) for the 
    machine learning algorithm (Fig.1). 
    `,
    apis: `
    This project uses the Simons CMAP public API to add environmental context to the compiled species measurements. Figure 2 shows a 
    selected list of datasets that can be accessed via the Simons CMAP database. Also, the excellent WoRMS REST API (marinespecies.org) has been
    used in this project to collect metadata (e.g. taxonomy) for each species.
    `


}
const missionStatement = ``


export default function About() {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <WaveBackground2 />
            <Container className={classes.allContents}>
                {/* <Typography variant="h2" align="center">About Three Quarters</Typography> */}
                <Card className={classes.mission}>

                    <Typography variant="h4" align="justify"> Author </Typography>
                    <Typography variant="body1" align="justify"> {textContent.intro} </Typography>
                    <Typography variant="h4" align="justify" style={{ marginTop: 20 }}> Three Quarters & Simons CMAP </Typography>

                    <Typography variant="body1" align="justify"> {textContent.body + textContent.apis} </Typography>

                    <Grid container spacing={3} style={{ marginTop: "0px" }} >
                        <Grid item md={6} sm={12}>
                            <Typography variant="caption" align="left"> 
                            Fig.1) An abstract expression of integrating several datasets with the marine species abundance dataset. 
                            </Typography>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            {/* <Graphs width={400} height={400} threeD={false} /> */}
                            <img src={cmapGraph} alt="cmap_graph" style={{ maxWidth: "70%"}} />
                            </div>
                        </Grid>
                        <Grid item md={6} sm={12}>
                            <Typography variant="caption" align="left"> Fig.2) An interactive and schematic view of a selected list of datasets in the Simons CMAP Database. </Typography>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Sunburst transparent={true} />
                            </div>
                        </Grid>
                        <Grid item md={12} sm={12}>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <img src={worms} alt="worms_logo" style={{ maxWidth: "30%"}} />
                            </div>
                        </Grid>
                    </Grid>

                </Card>
            </Container>
        </div>
    )
}
