import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import WaveBackground2 from '../../components/ui/backgrounds/waveBackground2';
import Publication from './Publication';
import { pubs } from './publicationList';



const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
        overflow: 'hidden',

    },
    title: {
        fontFamily: 'Poppins',
        fontSize: '4rem',
        marginBottom: theme.spacing(5),
    },  
    accordionContainer: {
        marginTop: '150px',
    },
}));



export default function Publications() {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className={classes.root}>
            <WaveBackground2 />
            <Container className={classes.accordionContainer}>
                <Typography variant="h1" className={classes.title} align="center">Publication List</Typography>
                {pubs.map((pub, index) => {
                    return (<Publication 
                                key={index.toString()} 
                                id={index.toString()} 
                                doi={pub.doi} 
                                title={pub.title} 
                                abstract={pub.abstract} 
                                thumbnail={pub.thumbnail} 
                                citation={pub.citation} 
                                handleChange={handleChange} 
                                expanded={expanded}
                    />)}           
                )}
            </Container>       
        </div>
    )
}


