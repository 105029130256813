import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import { Link, useLocation } from 'react-router-dom';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import useStyles from './mainNavbar.style';



export default function MainNavbar() {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = React.useState(0);

  const handleTabsChange = (event, index) => {
    setTabIndex(index);
  };

  


  const location = useLocation() 
  React.useEffect(() => {
    if (window.location.pathname === "/" && tabIndex !== 0) setTabIndex(0)
    else if (window.location.pathname === "/species" && tabIndex !== 1) setTabIndex(1)
    else if (window.location.pathname === "/dashboard" && tabIndex !== 2) setTabIndex(2)
    else if (window.location.pathname === "/ml" && tabIndex !== 3) setTabIndex(3)
    // else if (window.location.pathname === "/turbulence" && tabIndex !== 4) setTabIndex(4)
    // else if (window.location.pathname === "/publications" && tabIndex !== 4) setTabIndex(4)
    else if (window.location.pathname === "/about" && tabIndex !== 4) setTabIndex(4)
  }, [location])


  return (
    <AppBar className={classes.appbar} position="fixed" >
      <Toolbar>

        {/******  Logo ******/}
        {/* <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="home"
            href='/' 
          >
            <img className={classes.logo} src='/images/CMAP_White_Logo.png' alt='CMAP logo' />
        </IconButton> */}


        <Tabs className={classes.tabsContainer} value={tabIndex} onChange={handleTabsChange}>
          <Tab className={classes.tab} label="Home" component={Link} to="/"/>                          
          <Tab className={classes.tab} label="Species Compiler" component={Link} to="/species"/>
          <Tab className={classes.tab} label="Dashboard" component={Link} to="/dashboard"/>
          <Tab className={classes.tab} label="ML Models" component={Link} to="/ml"/>
          {/* <Tab className={classes.tab} label="Turbulence" component={Link} to="/turbulence"/> */}
          {/* <Tab className={classes.tab} label="Publications" component={Link} to="/publications"/> */}
          {/* <Tab className={classes.tab} label="About" component={Link} to="/about"/> */}
        </Tabs>    

      </Toolbar>
    </AppBar>
  );
}