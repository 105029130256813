import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Scatter from '../figs/highcharts/spline/Scatter';
import LabeledSwitch from '../../ui/labeled-switch/LabeledSwitch';
import { createColor, ColorPicker } from 'material-ui-color';
import { debounce } from 'lodash';
import { Grid, Stack, Typography } from '@mui/material';
import LabeledIOSSlider from '../../ui/labeled-ios-slider/LabeledIOSSlider';
import './eda.css'



function ScatterPanel({ df, columns, MAX_LAT, MIN_LAT, LAT_STEP, MAX_LON, MIN_LON, LON_STEP, MAX_DEPTH, MIN_DEPTH, DEPTH_STEP }) {
    const [state, setState] = useState({
        xlabel: 'lat',
        ylabel: 'Abund_cells_per_ml',
        x: df.array('lat'),
        y: df.array('Abund_cells_per_ml'),
        xAxisType: 'linear',
        yAxisType: 'linear',
        markerSize: 3,
        markerSizeCommited: 3,
        markerColor: createColor('rgba(124, 181, 236, 0.8)'),
        depthFilter: [MIN_DEPTH, MAX_DEPTH],
        latFilter: [MIN_LAT, MAX_LAT],
        lonFilter: [MIN_LON, MAX_LON],
    })


    const toggleXAxisType = () => {
        if (state.xAxisType === 'linear') { setState({...state, xAxisType: 'logarithmic'}) }
        else { setState({...state, xAxisType: 'linear'})  }
    }
    const toggleYAxisType = () => {
        if (state.yAxisType === 'linear') { setState({...state, yAxisType: 'logarithmic'}) }
        else { setState({...state, yAxisType: 'linear'}) }
    }


    const handleMarkerColorChange = (newValue) => {
        if (newValue) { setState({...state, markerColor: newValue}) }
    }


    const handleDepthFilterChange = (event, newRange) => {
        const fdf = df.filter(`d => (d.depth >= ${newRange[0]} && d.depth <= ${newRange[1]} && d.lat >= ${state.latFilter[0]} && d.lat <= ${state.latFilter[1]}  && d.lon >= ${state.lonFilter[0]} && d.lon <= ${state.lonFilter[1]} )`)
        setState({
            ...state, 
            depthFilter: newRange,
            x: fdf.array(state.xlabel),
            y: fdf.array(state.ylabel)
        })
    }
    const handleLatFilterChange = (event, newRange) => {
        const fdf = df.filter(`d => (d.depth >= ${state.depthFilter[0]} && d.depth <= ${state.depthFilter[1]} && d.lat >= ${newRange[0]} && d.lat <= ${newRange[1]}  && d.lon >= ${state.lonFilter[0]} && d.lon <= ${state.lonFilter[1]} )`)
        setState({
            ...state, 
            latFilter: newRange,
            x: fdf.array(state.xlabel),
            y: fdf.array(state.ylabel)
        })
    }
    const handleLonFilterChange = (event, newRange) => {
        const fdf = df.filter(`d => (d.depth >= ${state.depthFilter[0]} && d.depth <= ${state.depthFilter[1]} && d.lat >= ${state.latFilter[0]} && d.lat <= ${state.latFilter[1]}  && d.lon >= ${newRange[0]} && d.lon <= ${newRange[1]} )`)
        setState({
            ...state, 
            lonFilter: newRange,
            x: fdf.array(state.xlabel),
            y: fdf.array(state.ylabel)
        })
    }

    return (
        <Grid container spacing={2}>
            <Grid item md={12} lg={6}>

                <div className='scatter-controls' >
                    <Autocomplete
                        value={state.xlabel}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                const fdf = df.filter(`d => (d.depth >= ${state.depthFilter[0]} && d.depth <= ${state.depthFilter[1]} && d.lat >= ${state.latFilter[0]} && d.lat <= ${state.latFilter[1]}  && d.lon >= ${state.lonFilter[0]} && d.lon <= ${state.lonFilter[1]} )`)
                                setState({
                                    ...state,
                                    xlabel: newValue,
                                    x: fdf.array(newValue),
                                    ylabel: state.ylabel,
                                    y: fdf.array(state.ylabel),
                                    // latFilter: [MIN_LAT, MAX_LAT],
                                    // lonFilter: [MIN_LON, MAX_LON],
                                    // depthFilter: [MIN_DEPTH, MAX_DEPTH],                             
                                })
                            }
                        }}
                        id="xVariable"
                        options={columns}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="X-Axis" />}
                    />

                    <Autocomplete
                        value={state.ylabel}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                const fdf = df.filter(`d => (d.depth >= ${state.depthFilter[0]} && d.depth <= ${state.depthFilter[1]} && d.lat >= ${state.latFilter[0]} && d.lat <= ${state.latFilter[1]}  && d.lon >= ${state.lonFilter[0]} && d.lon <= ${state.lonFilter[1]} )`)
                                setState({
                                    ...state,
                                    ylabel: newValue,
                                    y: fdf.array(newValue),
                                    xlabel: state.xlabel,
                                    x: fdf.array(state.xlabel),
                                    // latFilter: [MIN_LAT, MAX_LAT],
                                    // lonFilter: [MIN_LON, MAX_LON],
                                    // depthFilter: [MIN_DEPTH, MAX_DEPTH],                              
                                })
                            }
                        }}
                        id="yVariable"
                        options={columns}
                        sx={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} label="Y-Axis" />}
                    />


                    <LabeledSwitch checked={state.xAxisType === 'logarithmic'} label={"Log X-Axis"} onClickHandler={toggleXAxisType} />
                    <LabeledSwitch checked={state.yAxisType === 'logarithmic'} label={"Log Y-Axis"} onClickHandler={toggleYAxisType} />


                    <LabeledIOSSlider
                        width={'90%'}
                        value={state.markerSize} min={0.5} max={10} step={0.5}
                        marks={[
                            { value: 2, label: '2' },
                            { value: 5, label: '5' },
                            { value: 8, label: '8' }
                        ]}
                        handleChange={ (event, newValue) => setState({...state, markerSize: newValue}) }
                        handleChangeCommitted={ (event, newValue) => setState({...state, markerSizeCommited: newValue})  }
                        label={'Marker Size'}
                    />

                    <Stack spacing={2} direction="row" sx={{ mb: 2 }} alignItems="center">
                        <Typography variant='body1' style={{ width: '60px' }}>Marker Color</Typography>
                        <ColorPicker hideTextfield defaultValue={state.markerColor} value={state.markerColor} onChange={debounce(handleMarkerColorChange, 100)} />
                    </Stack>
                </div>


                <div className='scatter-controls-wide '>
                    <LabeledIOSSlider
                        width={'90%'}
                        value={state.depthFilter} min={MIN_DEPTH} max={MAX_DEPTH} step={DEPTH_STEP}
                        marks={[
                            { value: MIN_DEPTH, label: `${MIN_DEPTH} m` },
                            { value: Math.round((MAX_DEPTH + MIN_DEPTH) / 2), label: `${Math.round((MAX_DEPTH + MIN_DEPTH) / 2)} m` },
                            { value: MAX_DEPTH, label: `${MAX_DEPTH} m` }
                        ]}
                        handleChange={(event, newValue) => setState({...state, depthFilter: newValue}) }
                        handleChangeCommitted={handleDepthFilterChange}
                        label={'Depth Filter'}
                    />
                    <LabeledIOSSlider
                        width={'90%'}
                        value={state.latFilter} min={MIN_LAT} max={MAX_LAT} step={LAT_STEP}
                        marks={[
                            { value: MIN_LAT, label: `${MIN_LAT} °N` },
                            { value: Math.round((MAX_LAT + MIN_LAT) / 2), label: `${Math.round((MAX_LAT + MIN_LAT) / 2)} °N` },
                            { value: MAX_LAT, label: `${MAX_LAT} °N` }
                        ]}
                        handleChange={(event, newValue) => setState({...state, latFilter: newValue})}
                        handleChangeCommitted={handleLatFilterChange}
                        label={'Latitude Filter'}
                    />
                    <LabeledIOSSlider
                        width={'90%'}
                        value={state.lonFilter} min={MIN_LON} max={MAX_LON} step={LON_STEP}
                        marks={[
                            { value: MIN_LON, label: `${MIN_LON} °E` },
                            { value: Math.round((MAX_LON + MIN_LON) / 2), label: `${Math.round((MAX_LON + MIN_LON) / 2)} °E` },
                            { value: MAX_LON, label: `${MAX_LON} °E` }
                        ]}
                        handleChange={(event, newValue) => setState({...state, lonFilter: newValue})}
                        handleChangeCommitted={handleLonFilterChange}
                        label={'Longitude Filter'}
                    />
                </div>


            </Grid>

            <Grid item md={12} lg={6}>
                <Scatter
                    x={state.x} y={state.y} title=""
                    xlabel={state.xlabel} ylabel={state.ylabel}
                    xAxisType={state.xAxisType} yAxisType={state.yAxisType}
                    markerSize={state.markerSizeCommited}
                    markerColor={`rgba(${state.markerColor.rgb[0]}, ${state.markerColor.rgb[1]}, ${state.markerColor.rgb[2]}, ${state.markerColor.alpha})`}
                />
            </Grid>

        </Grid>

    )
}


export default ScatterPanel
