
import React from 'react'
import { Divider, Typography } from '@material-ui/core'
import './eda.css'


function StatGrid({statReport}) {
    const placeHolder = "..."
    return (
        <div className="stat-grid">
        <Typography variant="h6">{"Sample Size"}</Typography>
        <Typography variant="h6">{"Dispersion Measures"}</Typography>
        <Typography variant="h6">{"Shape Measures"}</Typography>
        <Divider />
        <Divider />
        <Divider />
        <Typography variant="body1">{" "}</Typography>
        <Typography variant="body1">{`Max: ${(statReport && statReport.max) ? Number(statReport.max).toFixed(2) : placeHolder}`}</Typography>
        <Typography variant="body1">{" "}</Typography>
        <Typography variant="body1">{`Count: ${(statReport && statReport.count) ? statReport.count : placeHolder}`}</Typography>
        <Typography variant="body1">{`Q3: ${(statReport && statReport.q3) ? Number(statReport.q3).toFixed(2) : placeHolder}`}</Typography>
        <Typography variant="body1">{`Kurtosis: ${(statReport && statReport.kurtosis) ? Number(statReport.kurtosis).toFixed(2) : placeHolder}`}</Typography>
        <Typography variant="body1">{`Distinct: ${(statReport && statReport.distinct) ? statReport.distinct : placeHolder}`}</Typography>
        <Typography variant="body1">{`Mean: ${(statReport && statReport.mean) ? Number(statReport.mean).toFixed(2) : placeHolder}`}</Typography>
        <Typography variant="body1">{`Skewness: ${(statReport && statReport.skewness) ? Number(statReport.skewness).toFixed(2) : placeHolder}`}</Typography>
        <Typography variant="body1">{" "}</Typography>
        <Typography variant="body1">{`Median: ${(statReport && statReport.median) ? Number(statReport.median).toFixed(2) : placeHolder}`}</Typography>
        <Typography variant="body1">{" "}</Typography>
        <Typography variant="body1">{" "}</Typography>
        <Typography variant="body1">{`Q1: ${(statReport && statReport.q1) ? Number(statReport.q1).toFixed(2) : placeHolder}`}</Typography>
        <Typography variant="body1">{" "}</Typography>
        <Typography variant="body1">{" "}</Typography>
        <Typography variant="body1">{`Min: ${(statReport && statReport.min) ? Number(statReport.min).toFixed(2) : placeHolder}`}</Typography>
        <Typography variant="body1">{" "}</Typography>
        <Typography variant="body1">{" "}</Typography>
        <Typography variant="body1">{`STD: ${(statReport && statReport.std) ? Number(statReport.std).toFixed(2) : placeHolder}`}</Typography>

    </div>
    )
}

export default StatGrid
