

export const brightFrostyGlass = {
    background: 'rgba(255, 255, 255, 0.3)',
    border: '0.5px solid rgba(255, 255, 255, 0.5)',
    backdropFilter: 'blur(20px)',
    color: 'black',
};


export const darkFrostyGlass = {
    background: 'rgba(15, 14, 71, 0.3)',
    border: '0.5px solid rgba(255, 255, 255, 0.25)',
    backdropFilter: 'blur(20px)',
    color: 'white',
};