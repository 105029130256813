
let proRange = [
    ["T", 56317 - 5632, 56317 + 5632],
    ["T, P, S", 60514 - 9424, 60514 + 9424],
    ["P, S", 60794 - 10692, 60794 + 10692],
    ["T, P", 63744 - 17655, 63744 + 17655],
    ["P", 64451 - 19257, 64451 + 19257],
    ["S", 66086 - 24327, 66086 + 24327],
    ["ADT", 72090 - 25854, 72090 + 25854],
    ["CHL", 75806 - 26819, 75806 + 26819],
    ["T, N, O, S", 76539 - 27901, 76539 + 27901],
    ["T, N, CHL", 78140 - 28466, 78140 + 28466],
    ["T, N, O, CHL", 91381 - 32072, 91381 + 32072],
    ["N", 91796 - 34202, 91796 + 34202],
    ["Lat, Lon, Month", 112192 - 42899, 112192 + 42899],
    ["Month", 138415 - 60428, 138415 + 60428]
];

let proAvg = [
    ["T", 56317],
    ["T, P, S", 60514],
    ["P, S", 60794],
    ["T, P", 63744],
    ["P", 64451],
    ["S", 66086],
    ["ADT", 72090],
    ["CHL", 75806],
    ["T, N, O, S", 76539],
    ["T, N, CHL", 78140],
    ["T, N, O, CHL", 91381],
    ["N", 91796],
    ["Lat, Lon, Month", 112192],
    ["Month", 138415]
];


let synRange = [
    ["T, N, CHL", 13330-658, 13330+658],
    ["T, N, O, CHL", 13627-1355, 13627+1355],
    ["T, P, S", 14714-1656, 14714+1656],
    ["T, N, O, S", 15663-2061, 15663+2061],
    ["CHL", 15945-2525, 15945+2525],
    ["Lat, Lon, Month", 15958-2570, 15958+2570],
    ["S, P", 16287-3156, 16287+3156],
    ["T, P", 17976-3281, 17976+3281],
    ["N", 18724-3721, 18724+3721],
    ["Month", 18800-4123, 18800+4123],
    ["S", 18816-4284, 18816+4284],
    ["P", 18834-4930, 18834+4930],
    ["T", 19572-6256, 19572+6256],
    ["ADT", 20236-6576, 20236+6576]

];

let synAvg = [
    ["T, N, CHL", 13330],
    ["T, N, O, CHL", 13627],
    ["T, P, S", 14714],
    ["T, N, O, S", 15663],
    ["CHL", 15945],
    ["Lat, Lon, Month", 15958],
    ["S, P", 16287],
    ["T, P", 17976],
    ["N", 18724],
    ["Month", 18800],
    ["S", 18816],
    ["P", 18834],
    ["T", 19572],
    ["ADT", 20236]
];


let picoRange = [
    ["T, N, O, S", 9694-2871, 9694+2871],
    ["T, N, CHL", 10327-2927, 10327+2927],
    ["N", 10666-3068, 10666+3068],
    ["T, N, O, CHL", 10881-3855, 10881+3855],
    ["ADT", 10945-4211, 10945+4211],
    ["Lat, Lon, Month", 11541-4333, 11541+4333],
    ["T, P, S", 11576-4367, 11576+4367],
    ["S, P", 12499-5024, 12499+5024],
    ["T, P", 12589-5171, 12589+5171],
    ["P", 12685-5321, 12685+5321],
    ["T", 13123-5568, 13123+5568],
    ["S", 13531-6142, 13531+6142],
    ["Month", 14831-9946, 14831+9946]
];

let picoAvg = [
    ["T, N, O, S", 9694],
    ["T, N, CHL", 10327],
    ["N", 10666],
    ["T, N, O, CHL", 10881],
    ["ADT", 10945],
    ["Lat, Lon, Month", 11541],
    ["T, P, S", 11576],
    ["S, P", 12499],
    ["T, P", 12589],
    ["P", 12685],
    ["T", 13123],
    ["S", 13531],
    ["Month", 14831]
];


export const plots = [
    {
        title: "a) Feature Selection For The Prochlorococcus Model",
        plot: { data: proAvg, dataRange: proRange, ylabel: "Abundance Model Loss [cells/ml]" },
        caption: "",
    },
    {
        title: "b) Feature Selection For The Synechococcus Model",
        plot: { data: synAvg, dataRange: synRange, ylabel: "Abundance Model Loss [cells/ml]" },
        caption: "",
    },
    {
        title: "c) Feature Selection For The Picoeukaryotes Model",
        plot: { data: picoAvg, dataRange: picoRange, ylabel: "Abundance Model Loss [cells/ml]" },
        caption: "",
    },
]





export const videos = [
    {
        title: "Estimated Prochlorococcus Abundance",
        // link: "https://www.youtube.com/embed/lEnf5stbA4w",
        // link: "https://player.vimeo.com/video/676650891?h=a8f0d1f568&title=0&byline=0&portrait=0",
        link: "https://player.vimeo.com/video/808594288?h=a8f0d1f568&title=0&byline=0&portrait=0",
        caption: "",
    },
    {
        title: "Estimated Synechococcus Abundance",
        // link: "https://www.youtube.com/embed/pMP1HFPLddI",
        // link: "https://player.vimeo.com/video/676651334?h=fda4a0bc25&title=0&byline=0&portrait=0",
        link: "https://player.vimeo.com/video/808594374?h=fda4a0bc25&title=0&byline=0&portrait=0",        
        caption: "",
    },
    {
        title: "Estimated Picoeukaryotes Abundance",
        // link: "https://www.youtube.com/embed/1LxeA0EuB9I",
        // link: "https://player.vimeo.com/video/676651581?h=fda4a0bc25&title=0&byline=0&portrait=0",
        link: "https://player.vimeo.com/video/808594418?h=fda4a0bc25&title=0&byline=0&portrait=0",
        caption: "",
    },
]



