

import { takeLatest, call, put } from 'redux-saga/effects';
import stats from '../../utils/stats';
import * as dashActions from '../actions/actDashboard';
import * as dashActionsTypes from '../actionTypes/typDashboard';



function* calculateStat(action) {
    // yield put(interfaceActions.setLoadingMessage('Fetching Data'));

    let statReport = yield call(stats, action.payload.data);
    
    if(!statReport) {
        yield put(dashActions.actDasboardStatFailure());
    } else{
        yield put(dashActions.actDasboardStatSuccess(statReport));
    }

    // yield put(interfaceActions.setLoadingMessage(''));
}



/////////////////  Watchers  /////////////////

export function* watchStatCalc(){
    yield takeLatest(dashActionsTypes.STAT_CLAC_START, calculateStat);
}
