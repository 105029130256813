import { applyMiddleware, createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas/rootSaga';
import rootReducer from './reducers/rootReducer';
import {enhanceReduxMiddleware} from 'kepler.gl/middleware';



const sagaMiddleware = createSagaMiddleware();
// let middlewares = [sagaMiddleware];
const middlewares = enhanceReduxMiddleware([
    sagaMiddleware
  ]);
const enhancers = [applyMiddleware(...middlewares)];
// middlewares = process.env.NODE_ENV !== 'production' ? [...middlewares, require('redux-logger').createLogger()] : middlewares;
const store = createStore(rootReducer, compose(...enhancers));
sagaMiddleware.run(rootSaga);


export default store;