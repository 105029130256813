import React from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsExportData from 'highcharts/modules/export-data'
import Boost from 'highcharts/modules/boost';

Boost(Highcharts);


if (typeof(Highcharts) === 'object') {
  HighchartsExporting(Highcharts);
  HighchartsExportData(Highcharts);
}

function Scatter(props) {
    const chartOptions = {
        chart: {
            type: 'scatter',
            zoomType: 'xy',
            height: 500
        },
        title: {
            text: props.title
        },
        credits: {
            enabled: false
        },
        xAxis: {
            type: props.xAxisType,
            // dateTimeLabelFormats: {
            //     month: '%b. %e. %Y',
            //     year: '%Y'
            // },
            title: {
                text: props.xlabel
            }
        },
        yAxis: {
            type: props.yAxisType,
            title: {
                text: props.ylabel
            },
            // min: 0
        },
        plotOptions: {
            scatter: {
                marker: {
                    radius: props.markerSize
                }
            }
        },
        tooltip: {
            headerFormat: `<span style="font-size: 12px">${props.xlabel}:: {point.key:.2f}</span><br/>`,
            pointFormat: `<span style="font-size: 12px">${props.ylabel}: {point.y:.2f}</span><br/>`
        },
        series: [
            {
                name: props.ylabel,
                color: props.markerColor,
                boostThreshold: 1,
                data: props.x.map((e, i)=>[Number(e), Number(props.y[i])])
            }
        ],


        responsive: {
            rules: [{
                condition: {
                    maxWidth: 100,
                    minWidth: 50,
                },
                chartOptions: {
                    plotOptions: {
                        series: {
                            marker: {
                                radius: 2.5
                            }
                        }
                    }
                }
            }]
        }
    }



    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
    )
}

export default React.memo(Scatter)
