import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsExportData from 'highcharts/modules/export-data'
import Boost from 'highcharts/modules/boost';
import { query } from '../../../../api/rest';

Boost(Highcharts);


if (typeof(Highcharts) === 'object') {
  HighchartsExporting(Highcharts);
  HighchartsExportData(Highcharts);
}

function PackedBubble(props) {
    const [data, setData] = useState([])
    useEffect( async () => { 
        let where = [];
        let whereClause = '';
        if (props.filters.domain.length>0) {where.push(` Domain in (${props.filters.domain.map(i=>"'"+i.title+"'").join()})`)}
        if (props.filters.kingdom.length>0) {where.push(` Kingdom in (${props.filters.kingdom.map(i=>"'"+i.title+"'").join()})`)}
        if (props.filters.phylum.length>0) {where.push(` Phylum in (${props.filters.phylum.map(i=>"'"+i.title+"'").join()})`)}
        if (props.filters.Class.length>0) {where.push(` Class in (${props.filters.Class.map(i=>"'"+i.title+"'").join()})`)}
        if (props.filters.order.length>0) {where.push(` [Order] in (${props.filters.order.map(i=>"'"+i.title+"'").join()})`)}
        if (props.filters.family.length>0) {where.push(` Family in (${props.filters.family.map(i=>"'"+i.title+"'").join()})`)}
        if (props.filters.genus.length>0) {where.push(` Genus in (${props.filters.genus.map(i=>"'"+i.title+"'").join()})`)}
        if (props.filters.species.length>0) {where.push(` Species in (${props.filters.species.map(i=>"'"+i.title+"'").join()})`)}
        
        if (props.filters.trophic.length>0) {where.push(` Trophic_Level in (${props.filters.trophic.map(i=>"'"+i.title+"'").join()})`)}
        if (props.filters.subtrophic.length>0) {where.push(` SubTrophic_Level in (${props.filters.subtrophic.map(i=>"'"+i.title+"'").join()})`)}
        
        if (props.filters.functional.length>0) {where.push(` Functional_Group in (${props.filters.functional.map(i=>"'"+i.title+"'").join()})`)}
        if (props.filters.paraphyletic.length>0) {where.push(` Paraphyletic_Group in (${props.filters.paraphyletic.map(i=>"'"+i.title+"'").join()})`)}
        if (props.filters.unicell.length>0) {where.push(` Unicellularity in (${props.filters.unicell.map(i=>"'"+i.title+"'").join()})`)}

        // where.push(' Functional_Group is not null ')

        if (where.length>0) {whereClause = ` where ${where.join(' and ')}`}
        const data = await query(`                                        
                                select coalesce(fun.Functional_Group, 'unspecified') Functional_Group, o.[Name], count([name]) Value
                                from tblOrganism o 
                                left join tblOrgTaxon t on o.Organism_ID=t.Org_ID 
                                left join tblVariables v on o.Organism_ID=v.Org_ID 
                                left join tblOrgTrophic_Level p on p.Org_ID=o.Organism_ID
                                left join tblOrgTrophics ps on p.Trophic_ID=ps.Trophic_ID
                                left join tblOrgSubtrophics s on s.Subtrophic_ID=p.Subtrophic_ID
                                left join tblOrgFunctional_Group_WORMS fun on fun.Org_ID=o.Organism_ID
                                left join tblOrgParaphyletic_Group_WORMS par on par.Org_ID=o.Organism_ID
                                left join tblOrgUnicellularity_WORMS uni on uni.Org_ID=o.Organism_ID
                                ${whereClause}
                                group by fun.Functional_Group, o.[name]
                                order by fun.Functional_Group
                                `); 
        
        
        
        let s = [{name: data[0].Functional_Group, data: []}];
        for (let d of data) {
            if (s[s.length-1].name === d.Functional_Group) {
                s[s.length-1].data.push({name: d.Name, value: Number(d.Value)})

            }
            else {
                s.push({name: d.Functional_Group, data: [{name: d.Name, value: Number(d.Value)}]})
            }
          }

        setData(s);

    }, [props]);   

    const chartOptions = {
        chart: {
            type: 'packedbubble',
            height: 700
        },
        title: {
            text: ''
        },
        credits: {
            enabled: false
        },
        tooltip: {
            useHTML: true,
            pointFormat: '<b>{point.name}:</b> {point.value} datasets'
        },
        plotOptions: {
            packedbubble: {
                minSize: '20%',
                maxSize: '100%',
                zMin: 0,
                zMax: 800,
                layoutAlgorithm: {
                    gravitationalConstant: 0.05,
                    splitSeries: true,
                    seriesInteraction: false,
                    dragBetweenSeries: true,
                    parentNodeLimit: true
                },
                dataLabels: {
                    enabled: true,
                    format: '{point.name}',
                    filter: {
                        property: 'y',
                        operator: '>',
                        value: 100
                    },
                    style: {
                        color: 'black',
                        textOutline: 'none',
                        fontWeight: 'normal'
                    }
                }
            }
        },
        series: data,
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 100,
                    minWidth: 50,
                },
                chartOptions: {
                    plotOptions: {
                        series: {
                            marker: {
                                radius: 2.5
                            }
                        }
                    }
                }
            }]
        }
    }



    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
    )
}

export default React.memo(PackedBubble)



