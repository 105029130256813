

import * as dashboardActionTypes from '../actionTypes/typDashboard';



const INIT_STATE = {        
    dashboardData: [],
    statReport: {},
};



export default function dashboardReducer(state=INIT_STATE, action) {
    switch (action.type) {

        //////////// dashboard data slice ////////////
        case dashboardActionTypes.DASHBOARD_DATA: 
            return {
                ...state,
                dashboardData: action.payload.data
            }         


        //////////// dashboard stat slice ////////////
        case dashboardActionTypes.STAT_CALC_SUCCESS: 
            return {
                ...state,
                statReport: action.payload.statReport
            }                

        default: return state;    
    }
}; 
