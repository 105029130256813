
import {makeStyles } from '@material-ui/core/styles';
import { darkFrostyGlass } from '../../../style/textures';



const useStyles = makeStyles((theme) => ({
  appbar: {
    ...darkFrostyGlass
  },
    menuButton: {
      marginRight: theme.spacing(1),
    },
    logo: {
      width: 40,
      verticalAlign: 'middle',
      display: 'inline-block',
    },
    tabsContainer:{
      marginLeft: 'auto'

    },    
    tab:{
      fontFamily: 'Poppins',
      textTransform: 'none',
      fontSize: '1rem',
      fontWeight: 400,
      minWidth: 100,
    },       
  }));


  export default useStyles;