
export const serviceDescription = 
[
    {
        title: `Collect & Transform`,
        body: 
        `
        Collect 40 years of marine species abundance data and transform them into a unified data structure. 
        Species are annotated with taxonomy; a key attribute for search and integration.
        `,
    },
    {
        title: `Integrate`,
        body: 
        `
        Join the collected data with hundreds of contemporaneous environmental variables 
        (e.g. temperature, salinity, wind, etc..) derived from public datasets such as satellite observations.
        `,
    },
    {
        title: `Visualize`,
        body: 
        `
        Generate global maps of marine species using the built-in geospatial analysis tool and examine the association of
        species abundances with their immediate environmental conditions. 
        `,
    },
];




export const featuredSpecies =
[
{
    speciesName: "Emiliania",
    // embed: "https://app.vectary.com/p/6p0AWGX941ym0kbFH680EG",      
    embed: "https://app.vectary.com/viewer/v1/?model=f136e502-ea0d-4171-a5fc-e580cc94aea6&env=studio3&turntable=1",      
    body:
        `
        Emiliania huxleyi is a species of coccolithophore found in almost all ocean ecosystems from the equator to sub-polar regions, 
        and from nutrient rich upwelling zones to nutrient poor oligotrophic waters.  

        Coccolithophores are an important group of about 200 marine phytoplankton species which cover themselves with a calcium carbonate shell called a "coccosphere". 
        Coccolithophores are ecologically and biogeochemically important but the reason why they calcify remains elusive. 
        One key function may be that the coccosphere offers protection against microzooplankton predation, which is one of the main causes of phytoplankton death in the ocean.
        `,
    cite: ["https://en.wikipedia.org/wiki/Coccolithophore", "https://en.wikipedia.org/wiki/Emiliania_huxleyi"]
},
{
    speciesName: "Prochlorococcus",    
    embed: "https://app.vectary.com/viewer/v1/?model=ebe3fe07-7af8-40bc-8768-644f70a45334&env=studio3&turntable=1",           
    body:
        `
        Prochlorococcus is a genus of very small (0.6 μm) marine cyanobacteria with an unusual pigmentation (chlorophyll a2 and b2). 
        These bacteria belong to the photosynthetic picoplankton and are probably the most abundant photosynthetic organism on Earth. 
        Prochlorococcus microbes are among the major primary producers in the ocean, responsible for a large percentage of the photosynthetic production of oxygen. 
        Analysis of the genome sequences of 12 Prochlorococcus strains show that 1,100 genes are common to all strains, and the average genome size is about 2,000 genes. 
        In contrast, eukaryotic algae have over 10,000 genes.
        `,
    cite: ["https://en.wikipedia.org/wiki/Prochlorococcus"],
},    
{
    speciesName: "Alexandrium",
    // embed: "https://app.vectary.com/p/5tJv49VGfThU5yPckk9kwW",      
    embed: "https://app.vectary.com/viewer/v1/?model=c19a0cf4-f7f0-4a52-b8d4-69df58142fbc&env=studio3&turntable=1" ,      
    body:
        `
        Alexandrium is a genus of dinoflagellates. It contains some of the dinoflagellate species most harmful to humans, 
        because it produces toxic harmful algal blooms that cause paralytic shellfish poisoning in humans. 

        The group was not well-defined in the past and thus, due to interest of its toxic behaviour, people have revised the 
        group through formal tabulation and usage of molecular markers such as the toxic gene, stx4A, to better understand not 
        just the relationship between each species; but more importantly, to tell something about the spread and barriers to the bloom. 
        Even if, much is known about the life cycles related to the blooms, much is still unknown as to how each life stage is triggered and transitions to the next stage. 
        Predation and parasitism have proven to be great barriers for population growth. Learning about the pathways of toxin synthesis can allow us to determine how species 
        of Alexandrium have adapted and co-evolved to persist.
        `,
    cite: ["https://en.wikipedia.org/wiki/Alexandrium_(dinoflagellate)"],
},
{
    speciesName: "Dinophysis Caudata",
    embed: "https://app.vectary.com/viewer/v1/?model=7a7b7523-2da2-42e6-a59d-0525bda26626&env=studio3&turntable=1",      
    body:
        `
        Dinophysis caudata is an armoured, marine, planktonic dinoflagellate species. 
        It is a bloom-forming species associated with massive fish kills. 
        It is commonly found world-wide in subtropical and tropical neritic waters.
        `,
    cite: ["http://species-identification.org/species.php?species_group=dinoflagellates&id=51"],
},
{
    speciesName: "Ornithocercus",
    embed: "https://app.vectary.com/viewer/v1/?model=891fb87e-9b6a-4e88-981a-2612f8b95a63&env=studio3&turntable=1",      
    body:
        `
        Ornithocercus is a genus of planktonic dinoflagellate that is known for its complex morphology that features considerable lists growing from its thecal plates, giving an attractive appearance. 
        Discovered in 1883, this genus has a small number of species currently categorized but is widespread in tropical and sub-tropical oceans. 
        The genus is marked by exosymbiotic bacteria gardens under its lists, the inter-organismal dynamics of which are a current field of research. 
        As they reside only in warm water, the genus has been used as a proxy for climate change and has potential to be an indicator species for environmental change if found in novel environments.
        `,
    cite: ["https://en.wikipedia.org/wiki/Ornithocercus"],
},
{
    speciesName: "Synechococcus",    
    embed: "https://app.vectary.com/viewer/v1/?model=35bca98a-e53a-4b11-a930-24040df13179&env=studio3&turntable=1",      
    body:
        `
        Synechococcus is a unicellular cyanobacterium that is very widespread in the marine environment. 
        Its size varies from 0.8 to 1.5 µm. The photosynthetic coccoid cells are preferentially found in well-lit surface waters where 
        it can be very abundant. Many freshwater species of Synechococcus have also been described.
        `,
    cite: ["https://en.wikipedia.org/wiki/Synechococcus"],
},

]
