import React from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsExportData from 'highcharts/modules/export-data'
import HighchartsAccessibility from 'highcharts/modules/accessibility'
import HighchartsHistoBell from 'highcharts/modules/histogram-bellcurve'
import Boost from 'highcharts/modules/boost';

import useWindowSize from '../../../../../utils/useWindowSize'

Boost(Highcharts);


if (typeof (Highcharts) === 'object') {
    HighchartsExporting(Highcharts);
    HighchartsExportData(Highcharts);
    HighchartsAccessibility(Highcharts);
    HighchartsHistoBell(Highcharts);
}

function HistoScatter(props) {
    const { height, width } = useWindowSize();

    const chartOptions = {
        chart: {
            zoomType: 'xy',
            width: Math.round(0.49*width)
        },
        title: {
            text: props.title
        },
        credits: {
            enabled: false
        },
        xAxis: [{
            title: { text: props.ylabel },
            alignTicks: false,
            opposite: true
        }, {
            title: { text: `${props.xlabel} (Histogram)` },
            alignTicks: false,
            
        }],

        yAxis: [{
            title: { text: props.xlabel }
        }, {
            title: { text: `Count (Histogram)` },
            opposite: true
        }],


        // tooltip: {
        //     headerFormat: `<span style="font-size: 12px">${props.xlabel}:: {point.key:.2f}</span><br/>`,
        //     pointFormat: `<span style="font-size: 12px">${props.ylabel}: {point.y:.2f}</span><br/>`
        // },

        plotOptions: {
            histogram: {
                accessibility: {
                    point: {
                        valueDescriptionFormat: '{index}. {point.x:.3f} to {point.x2:.3f}, {point.y}.'
                    }
                }
            }
        },



        series: [{
            name: 'Histogram',
            type: 'histogram',
            boostThreshold: 1,
            xAxis: 1,
            yAxis: 1,
            baseSeries: 's1',
            zIndex: -1
        }, {
            visible: props.hideScatter ? false : true,
            name: 'Scatter',
            type: 'scatter',
            boostThreshold: 1,
            data: props.x.map((e, i) => [Number(props.y[i]), Number(e)]),
            id: 's1',
            marker: {
                radius: 2.5
            }
        }],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 100,
                    minWidth: 50,
                },
                chartOptions: {
                    plotOptions: {
                        series: {
                            marker: {
                                radius: 2.5
                            }
                        }
                    }
                }
            }]
        }
    }


    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
    )
}

export default React.memo(HistoScatter)
