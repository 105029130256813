import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@mui/material';
import { Box, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { brightFrostyGlass } from '../../style/textures'


const useStyles = makeStyles((theme) => ({
    tagline: {
        // ...brightFrostyGlass,
        // backdropFilter: 'blur(2px)',
        display: "grid",
        gap: "20px",
        // paddingTop: "30px",
        paddingLeft: "30px",
        marginLeft: "auto",
        marginRight: "auto",
        width: "70%",
        borderRadius: "5px",
    },
    leftPanel: {
        marginTop: "200px"
    },
    compileButtonDiv: {
        marginTop: "150px",
    },
    compileButton: {
        ...brightFrostyGlass,
        backdropFilter: 'blur(5px)',
        width: "300px", 
        height: "50px", 
        border: "2px solid"
    }

}));




export default function LeftPanel() {
    const classes = useStyles()
    const history = useHistory()
    return (
        <div className={classes.leftPanel}>            
            <div className={classes.tagline}>
                <Typography variant="h2">Marine Microbes &</Typography>
                <Typography variant="h2">Machine Learning</Typography>
            </div>

            <Grid item xs={12} xl={12} >
                <div className={classes.compileButtonDiv} >
                    <Box display="flex" justifyContent="center">
                        <Button
                            className={classes.compileButton}
                            variant="outlined"                            
                            onClick={() => history.push('/species')}
                        >
                            Compile Marine Species Data
                        </Button>
                    </Box>
                </div>
            </Grid>
        </div>

    )
}
