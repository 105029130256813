import React, {useState, useEffect} from 'react'
import { query } from '../../api/rest'
import SpeciesOptions from '../../components/species/species-options/SpeciesOptions'



const Species = () => {
    
    
    const [domain, setDomain] = useState([])
    const [kingdom, setKingdom] = useState([])
    const [phylum, setPhylum] = useState(([]))
    const [Class, setClass] = useState(([]))
    const [order, setOrder] = useState(([]))
    const [family, setFamily] = useState(([]))
    const [genus, setGenus] = useState(([]))
    const [species, setSpecies] = useState(([]))
    
    const [trophicLevels, setTrophicLevels] = useState(([]))
    const [subtrophicLevels, setSubtrophicLevels] = useState(([]))
    
    const [functional, setFunctional] = useState(([]))
    const [paraphyletic, setParaphyletic] = useState(([]))
    const [unicell, setUnicell] = useState(([]))

    // useEffect( () => { 
    //     async function fetchData() {
    //         try {
    //             const data = await query('select @@servername') 
    //             setSnakeyData(data);
    //         } catch (err) {
    //             console.log(err);
    //         }
    //     }
    //     fetchData();
    // }, []);


    ///////////  taxonomy options  //////////
    useEffect( async () => { 
        async function fetchData() {
            return query(`                                        
                        select distinct Domain as [title] from tblOrgTaxon where Domain is not null
                        `); 
        }                        
        const data = await fetchData()
        setDomain(data);
    }, []); 

    useEffect( async () => { 
        async function fetchData() {
                    return query(`                                        
                                select distinct Kingdom as [title] from tblOrgTaxon where Kingdom is not null
                                `); 
        }                                    
        const data = await fetchData()
        setKingdom(data);
    }, []);   

    useEffect( async () => { 
        async function fetchData() {
            return query(`                                        
                        select distinct Phylum as [title] from tblOrgTaxon where Phylum is not null
                        `); 
        }
        const data = await fetchData()
        setPhylum(data);
    }, []);   


    useEffect( async () => { 
        async function fetchData() {
            return query(`                                        
                        select distinct Class as [title] from tblOrgTaxon where Class is not null
                        `); 
        }
        const data = await fetchData()                        
        setClass(data);
    }, []);   

    useEffect( async () => { 
        async function fetchData() {
                return query(`                                        
                            select distinct [Order] as [title] from tblOrgTaxon where [Order] is not null
                            `); 
        }
        const data = await fetchData()                       
        setOrder(data);
    }, []);   

    useEffect( async () => { 
        async function fetchData() {
            return query(`select distinct Family as [title] from tblOrgTaxon where Family is not null`); 
        }
        const data = await fetchData()
        setFamily(data);
    }, []);   

    useEffect( async () => { 
        async function fetchData() {
            return query(`select distinct Genus as [title] from tblOrgTaxon where Genus is not null`); 
        }
        const data = await fetchData()   
        setGenus(data);
    }, []);   
    
    useEffect( async () => { 
        async function fetchData() {
            return query(`select distinct Species as [title] from tblOrgTaxon where Species is not null`); 
        }
        const data = await fetchData()
        setSpecies(data);
    }, []);   

    useEffect( async () => { 
        async function fetchData() {
            return query(`                                        
                        --select distinct Trophic_Level as [title] from tblOrgTrophic_Level ot
                        --left join tblOrgTrophics t on t.Trophic_ID=ot.Trophic_ID
                        select Trophic_Level as [title] from tblOrgTrophics
                        `); 
        }
        const data = await fetchData()                
        setTrophicLevels(data);
    }, []);  
    
    useEffect( async () => { 
        async function fetchData() {
            return query(`                                        
                        --select distinct Subtrophic_Level as [title] from tblOrgTrophic_Level ot
                        --left join tblOrgSubtrophics s on s.Subtrophic_ID=ot.Subtrophic_ID
                        select SubTrophic_Level as [title] from tblOrgSubTrophics
                        `); 
        }
        const data = await fetchData()                   
        setSubtrophicLevels(data);
    }, []); 
    

    useEffect( async () => { 
        async function fetchData() {
            return query(`select distinct Functional_Group as [title] from tblOrgFunctional_Group_WORMS where Long_Functional_Group is not null`); 
        }
        const data = await fetchData()   
        setFunctional(data);
    }, []);      


    useEffect( async () => { 
        async function fetchData() {
            return query(`select distinct Paraphyletic_Group as [title] from tblOrgParaphyletic_Group_WORMS where Paraphyletic_Group is not null`); 
        }
        const data = await fetchData()   
        setParaphyletic(data);
    }, []);      


    useEffect( async () => { 
        async function fetchData() {
            return query(`select distinct Unicellularity as [title] from tblOrgUnicellularity_WORMS where Unicellularity is not null`); 
        }
        const data = await fetchData()   
        setUnicell(data);
    }, []);      
    

    return (
        <div>
            <SpeciesOptions 
            domain={domain} kingdom={kingdom} phylum={phylum} Class={Class} order={order} family={family} genus={genus} species={species} 
            trophicLevels={trophicLevels} subtrophicLevels={subtrophicLevels}
            functional={functional} paraphyletic={paraphyletic} unicell={unicell}/>
            
        </div>
    )
}

export default Species